import React from 'react'
import { toast } from 'react-toastify'
import IconWidget from '../_widget_icon'
import OptionContainer from '../OptionControl'
import SettingsForm from './SettingsForm'
import SettingsWeight from './SettingsWeight'
import { startCase } from '../utils'
import { snakeCase } from 'lodash'

class SettingsControl extends React.Component {
  static defaultProps = {
    settings: {
      tosHeading: '',
      tosCopy: '',
      tosLink: '',
      tosLabel: '',
      locations: {},
      sizes: {},
      weights: {
        green: 60,
        orange: 30,
        red: 10,
      },
    },
  }

  static getDerivedStateFromProps(nextProps, state) {
    const props = state.locations ? state : nextProps.settings
    return SettingsControl.mapProps(props)
  }

  static mapProps(props) {
    const { locations } = props

    if (locations) {
      const newLocations = Object.keys(locations).reduce((acc, key) => {
        const location = locations[key]
        let id = snakeCase(key)
        if (id === 'kith_l_a') {
          id = 'kith_la'
        }
        acc[id] = location
        return acc
      }, {})
      props.locations = newLocations
    }

    return props
  }

  constructor(props) {
    super(props)
    this.state = SettingsControl.mapProps(this.props.settings)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.handleOptionsUpdate = this.handleOptionsUpdate.bind(this)
    this.renderOptionCards = this.renderOptionCards.bind(this)
    this.onWeightChange = this.onWeightChange.bind(this)
  }

  onFormSubmit(e) {
    e.preventDefault()
    const payload = this.state
    toast.info('Saving...')

    return this.props
      .saveSettings(payload)
      .then(() => toast.success('Settings saved!'))
      .catch((error) => {
        console.error(error)
        return toast.error('Oops! Something went wrong...')
      })
  }

  onInputChange(e) {
    const stateChanges = (target = {}) => (prevState) => {
      const { name, value } = target
      return !name ? null : { [name]: value }
    }

    return this.setState(stateChanges(e.target))
  }

  handleOptionsUpdate(options, id) {
    this.setState({ [id]: options })
  }

  renderOptionCards(options) {
    return Object.keys(options).map((key) => {
      const casedKey = startCase(key)
      const props = {
        key,
        id: key,
        title: casedKey,
        onOptionsUpdate: this.handleOptionsUpdate,
        options: this.state[key],
        addText: 'Add ' + casedKey.toLowerCase().slice(0, -1),
        singleValue: key === 'locations',
        boxCount: key === 'locations' ? 2 : 1,
      }

      return <OptionContainer {...props} />
    })
  }

  onWeightChange(name, value) {
    const stateChanges = (prevState) => ({
      weights: {
        ...prevState.weights,
        [name]: value,
      },
    })

    return this.setState(stateChanges)
  }

  render() {
    const { title, history } = this.props
    const {
      locations = {},
      weights = {},
      shoeSizes = {},
    } = this.state
    const { green, orange, red } = weights

    const options = {
      locations,
      shoeSizes,
    }

    return (
      <div id="outer-container" style={{ height: '100%' }}>
        <section className="container">
          <div className="full-width">
            <article>
              <div className="title-div">
                <button className="secondary" onClick={history.goBack}>
                  <IconWidget icon="fa-long-arrow-left" /> Back
                </button>
              </div>
              <h2 style={{ marginBottom: '0', marginLeft: '1rem' }}>{title}</h2>
            </article>

            <article>
              <div className="full-width">
                <div className="column eight">
                  <SettingsForm
                    onFormSubmit={this.onFormSubmit}
                    onInputChange={this.onInputChange}
                    tosHeading={this.state.tosHeading}
                    tosCopy={this.state.tosCopy}
                    tosLabel={this.state.tosLabel}
                    tosLink={this.state.tosLink}
                    history={history}
                  />
                </div>
                <div className="column four">
                  <div className="card">
                    <SettingsWeight
                      green={green}
                      orange={orange}
                      red={red}
                      onWeightChange={this.onWeightChange}
                    />
                    <div className="row">
                      <button
                        className="button-100"
                        type="submit"
                        onClick={this.onFormSubmit}
                      >
                        <IconWidget icon="fa-check" />
                        {' Save'}
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    {this.renderOptionCards(options)}
                    <div className="row">
                      <button
                        className="button-100"
                        type="submit"
                        onClick={this.onFormSubmit}
                      >
                        <IconWidget icon="fa-check" />
                        {' Save'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    )
  }
}

export default SettingsControl
