import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import './RichTextEditor.css';

class RichTextEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      focused: false,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFocus() {
    this.setState({ focused: true });
  }

  handleBlur() {
    this.setState({ focused: false });
  }

  handleChange(editorState) {
    return this.props.onEditorStateChange(editorState, this.props.id);
  }

  render() {
    const {
      id,
      label,
      onEditorStateChange,
      editorState,
      wrapperClassName,
      ...rest
    } = this.props;

    const wrapperClassname = this.state.focused
      ? wrapperClassName + ' editor-wrapper--focus'
      : wrapperClassName;

    const toolbarProps = {
      options: [
        'blockType',
        'fontSize',
        'list',
        'textAlign',
        'colorPicker',
        'link',
        'image',
      ],
      inline: { inDropdown: true },
      list: { inDropdown: true },
      textAlign: { inDropdown: true },
      link: { inDropdown: true },
    };

    return (
      <div className="RichTextEditor">
        {label && <label>{label}</label>}
        <Editor
          {...rest}
          toolbar={toolbarProps}
          wrapperClassName={wrapperClassname}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          editorState={editorState}
          onEditorStateChange={this.handleChange}
        />
      </div>
    );
  }
}

const noop = () => {};
RichTextEditor.defaultProps = {
  onEditorStateChange: noop,
};

export default RichTextEditor;
