import React from 'react';
import OptionFields from './OptionFields';
import { isEqual, noop } from 'lodash';

class OptionControl extends React.Component {
  static defaultProps = {
    onOptionRemove: noop,
    onOptionSave: noop,
    onEditOpen: noop,
    onEditClose: noop,
    editMode: false,
    id: '',
    title: '',
    values: [],
    boxCount: 1,
    fromState: '',
  };

  static getDerivedStateFromProps(props, state) {
    let changes = null;

    if (props.title !== state.title) {
      changes = changes || {};

      if (state.fromState === 'title') {
        changes.title = state.title;
        changes.fromState = '';
      } else {
        changes.title = props.title;
      }
    }

    if (!isEqual(props.values, state.values)) {
      changes = changes || {};

      if (state.fromState === 'values') {
        changes.values = state.values;
        changes.fromState = '';
      } else {
        changes.values = props.values;
      }
    }

    return changes;
  }

  constructor(props) {
    super(props);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleOptionSave = this.handleOptionSave.bind(this);
    this.handleOptionRemove = this.handleOptionRemove.bind(this);
    this.handleOptionTitleChange = this.handleOptionTitleChange.bind(this);
    this.handleOptionValueChange = this.handleOptionValueChange.bind(this);
    this.handleEditOpen = this.handleEditOpen.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      title: this.props.title,
      values: this.props.values,
      error: false,
    };
  }

  handleOptionSave() {
    // const refValue = this.inputRef.current.value;
    let { title, values } = this.state;
    const payload = { title, values };

    return !title
      ? this.setState({ error: true })
      : this.props.onOptionSave(payload, this.props.id);
  }

  handleOptionRemove() {
    return this.props.onOptionRemove(this.props.id);
  }

  handleEditOpen() {
    return this.props.onEditOpen(this.props.id);
  }

  handleEditClose() {
    const { title, values, id, onEditClose } = this.props;
    return !title
      ? this.handleOptionRemove()
      : this.setState({ title, values }, () => onEditClose(id));
  }

  handleOptionTitleChange(title) {
    this.setState({ title, fromState: 'title' });
  }

  handleOptionValueChange(values) {
    this.setState({ values, fromState: 'values' });
  }

  handleInputKeyDown(e) {
    const { which } = e;
    if (which === 13) {
      this.handleOptionSave();
    }
  }

  render() {
    const { id, editMode, preventDelete } = this.props;
    const isEmpty = !this.state.title && !this.state.values.length;
    const isNew = !this.props.title && !this.props.values.length;
    const canDelete = !preventDelete && !isNew;

    const deleteButtonMarkup = canDelete && (
      <div className="button-group pull-left">
        <button
          className="secondary icon-trash"
          onClick={this.handleOptionRemove}
        />
      </div>
    )

    const secondaryAction = {
      className: 'secondary icon-close',
      handler: this.handleEditClose,
      text: '',
    };

    const primaryAction = {
      className: 'secondary icon-checkmark',
      handler: this.handleOptionSave,
      text: '',
    };

    if (!editMode) {
      return (
        <div className="row">
          <div className="input-group">
            <input
              type="text"
              readOnly
              value={this.state.title}
              onClick={this.handleEditOpen}
              className="OptionCtrl-Edit"
            />
            <button
              className="secondary icon-edit"
              onClick={this.handleEditOpen}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <OptionFields
          id={id}
          title={this.state.title}
          values={this.state.values}
          titleError={this.state.error}
          onTitleChange={this.handleOptionTitleChange}
          onValueChange={this.handleOptionValueChange}
          onInputKeyDown={this.handleInputKeyDown}
          onSave={this.handleOptionSave}
          readOnly={this.props.preventDelete}
          inputRef={this.inputRef}
          singleValue={this.props.singleValue}
          boxCount={this.props.boxCount}
        />
        <div className="row">
          {deleteButtonMarkup}
          <div className="button-group pull-right" style={{ marginRight: '0' }}>
            <button
              className={secondaryAction.className}
              onClick={secondaryAction.handler}
            >
              {secondaryAction.text}
            </button>
            <button
              className={primaryAction.className}
              onClick={primaryAction.handler}
              disabled={isEmpty}
            >
              {primaryAction.text}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default OptionControl;
