import React from 'react';
// import RichTextEditor from '../RichTextEditor';

const ProductForm = props => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="column six">
          Product Title<br />
          <input
            type="text"
            name="productTitle"
            autoComplete="off"
            value={props.productTitle}
            onChange={props.onInputChange}
          />
        </div>
        <div className="column six">
          Product Sub Title<br />
          <input
            type="text"
            name="productSubTitle"
            autoComplete="off"
            value={props.productSubTitle}
            onChange={props.onInputChange}
          />
        </div>
      </div>

      <div className="row">
        <div className="column twelve">
          Product Price<br />
          <input
            type="text"
            name="productPrice"
            autoComplete="off"
            value={props.productPrice}
            onChange={props.onInputChange}
          />
        </div>
      </div>

      {/* <div className="row">
        <div className="column twelve">
          Product Image<br />
          <small>
            Upload image to Shopify then copy the CDN URL below, this will be
            optimal for page speed
          </small>
          <input
            type="text"
            name="productImage"
            autoComplete="off"
            value={props.productImage}
            onChange={props.onInputChange}
          />
        </div>

        {props.productImage && (
          <div className="column twelve">
            <img alt="" src={props.productImage} className="campaign-image" />
          </div>
        )}
      </div>

      <div className="row">
        <div className="column twelve">
          <RichTextEditor
            id="productDescription"
            label="Product Description"
            wrapperClassName="editor-wrapper"
            editorClassName="editor-editor"
            onEditorStateChange={props.onEditorStateChange}
            editorState={props.productDescription}
          />
        </div>
      </div> */}
    </React.Fragment>
  );
};

const noop = () => {};
ProductForm.defaultProps = {
  onInputChange: noop,
  onEditorStateChange: noop,
  productTitle: '',
  productSubTitle: '',
  productPrice: '',
  productImage: '',
};

export default ProductForm;
