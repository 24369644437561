import React from 'react';
import { render } from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './index.css';
import Verify from './Verify';
import Campaigns from './Campaigns';
import CampaignNew from './CampaignNew';
import CampaignEdit from './CampaignEdit';
import CampaignSubmissions from './CampaignSubmissions';
import CampaignProducts from './CampaignProducts';
import AccountNew from './AccountNew';
import AccessDenied from './AccessDenied';
import CampaignProduct from './CampaignProduct';
import Settings from './Settings';

import { unregister } from './registerServiceWorker';

import { AnimatedSwitch } from 'react-router-transition';

console.log('HELLO VERSION 2.6.3.1!');
console.log('refined location filtering extended to 300...');

render(
  <Router>
    <div>
      <ToastContainer
        position="top-right"
        type="default"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        <Route exact path="/" component={Verify} />
        <Route exact path="/auth" component={Verify} />
        <Route path="/settings" component={Settings} />
        <Route path="/dashboard" component={Campaigns} />
        <Route path="/campaigns" component={Campaigns} />
        <Route path="/campaign-new" component={CampaignNew} />
        <Route exact path="/campaign/:id" component={CampaignEdit} />
        <Route
          exact
          path="/campaign/:campaignId/product/new"
          component={CampaignProduct}
        />
        <Route
          exact
          path="/campaign/:campaignId/product/:productId"
          component={CampaignProduct}
        />
        <Route path="/campaign-products/:id" component={CampaignProducts} />
        <Route path="/account/new" component={AccountNew} />
        <Route path="/access-denied" component={AccessDenied} />
        <Route
          path="/campaign-submissions/:id/:winners_notified"
          component={CampaignSubmissions}
        />
      </AnimatedSwitch>
    </div>
  </Router>,
  document.getElementById('root')
);
unregister();
