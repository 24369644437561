import React from 'react';
import DataProvider from './DataProvider';
import ProductControl from './ProductControl';
import fire, { firebase } from './_fire';
import { camelizeSnake, snakifyCamel, pick } from './utils';

class CampaignProduct extends React.Component {
  constructor(props) {
    super(props);
    const { location, match } = this.props;
    const { state: locationState } = location;
    const { params: { campaignId, productId } } = match;

    const campaignRef = fire
      .firestore()
      .collection('campaigns')
      .doc(campaignId);

    const query = campaignRef.collection('products');
    const editProductState = () => ({
      title: 'Edit product',
      operation: 'update',
      productRef: query.doc(productId),
      productId: productId,
      campaignUpdateKey: `products.${productId}`,
    });

    const addProductState = () => ({
      title: 'Add product',
      operation: 'set',
      productRef: query.doc(),
      get productId() {
        return this.productRef.id;
      },
      get campaignUpdateKey() {
        return `products.${this.productRef.id}`;
      },
    });

    this.state = locationState
      ? { product: locationState, campaignRef, ...editProductState() }
      : { campaignRef, ...addProductState() };

    this.saveProduct = this.saveProduct.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
  }

  componentDidMount() {
    if (this.state.operation === 'update') {
      return this.state.productRef
        .get()
        .then(doc => (doc.exists ? doc.data() : {}))
        .then(camelizeSnake)
        .then(product => this.setState({ product }))
        .catch(console.error);
    }
  }

  saveProduct(payload) {
    const {
      productRef,
      operation,
      campaignRef,
      campaignUpdateKey,
      productId,
    } = this.state;
    const data = snakifyCamel({ ...payload, productId });
    return productRef[operation](data).then(() =>
      campaignRef.update(campaignUpdateKey, data)
    );
  }

  processSettings(data) {
    return pick([
      'apparelBottomSizes',
      'apparelTopSizes',
      'locations',
      'shoeSizes',
    ])(camelizeSnake(data));
  }

  removeProduct() {
    const { productRef, campaignRef, campaignUpdateKey } = this.state;
    return productRef
      .delete()
      .then(() =>
        campaignRef.update(
          campaignUpdateKey,
          firebase.firestore.FieldValue.delete()
        )
      );
  }

  render() {
    const { title, product } = this.state;
    const { history, match: { params } } = this.props;
    return (
      <DataProvider
        query={[['collection', 'settings'], ['doc', 'kithnyc.myshopify.com']]}
        processStrategy={this.processSettings}
        render={settings => (
          <ProductControl
            history={history}
            product={product}
            settings={settings || {}}
            saveProduct={this.saveProduct}
            removeProduct={!!product && this.removeProduct}
            campaignId={params.campaignId}
            productId={params.productId}
            title={title || 'Add Product'}
          />
        )}
      />
    );
  }
}

export default CampaignProduct;
