import React, { Component } from 'react';
import Auth from './Auth';

class Verify extends Component {

  componentDidMount(){

    var urlParams = new URLSearchParams(window.location.search);
    var jwt_token = urlParams.get('token');
    console.log(jwt_token);
    console.log(localStorage.getItem('token') );
    if(jwt_token){
      Auth.authenticateUser(jwt_token)
    }

    if (Auth.isUserAuthenticated() || process.env.NODE_ENV === 'development') {
      window.location = '/dashboard';
    } else {
      window.location = '/access-denied';
    }

  }

  render() {
    return (
      <div className="App">
        Verifying...
      </div>
    );
  }
}
export default Verify;
