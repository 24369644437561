import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import LoadingIcon from './_widget_loader.js';
import IconWidget from './_widget_icon';
import fire from './_fire';
import SubmissionCount from './_submission_count';

import './App.css';
import './uptown.css';
import './Account.css';

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMessage: 'Loading Campaigns',
      loadingState: 'show-loading',
      AccountView: 'hide-Account',
      listView: 'show-Account-list',
      has_no_data: 'hide-no-data',
      has_no_data_message: 'There are no Campaigns saved',
      data: [],
      firstLoad: true,
      showArchived: false,
      showArchivedText: 'Show Archived',
    };

    this.observer = null
  }

  getCampaigns(showArchived) {
    var xref = fire
      .firestore()
      .collection('campaigns')
      .where('is_archived', '==', showArchived);

    return xref.onSnapshot(doc => {
      var docs = [];
      doc.forEach(doc => {
        var data = {
          id: doc.id,
          data: doc.data(),
        };
        docs.push(data);
      });
      this.setState({
        loadingState: 'hide-loading',
        data: docs,
      });
    }, console.error);
  }

  componentDidMount() {
    this.observer = this.getCampaigns(false);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const stateChanges = prevState => {
      return {
        showArchived: !prevState.showArchived,
        showArchivedText: prevState.showArchived ? 'Show Archived' : 'Hide Archived'
      }
    }

    return this.setState(stateChanges, () => {
      this.observer = this.getCampaigns(this.state.showArchived)
    })
  }

  render() {
    const Accounts = this.state.data.map((el, index) => {
      let status_tag = 'blue';
      let status_txt = 'Active';

      let start_date = moment(el.data.start_date, 'YYYY-MM-DDTHH:mm').format(
        'MM/DD/YY hh:mm A zz'
      );
      let end_date = moment(el.data.end_date, 'YYYY-MM-DDTHH:mm').format(
        'MM/DD/YY hh:mm A zz'
      );

      if (el.data.is_archived === true) {
        status_tag = 'red';
        status_txt = 'Archived';
      }

      axios({
        method:'get',
        url: `https://us-central1-raffleapp-madebyseed.cloudfunctions.net/app/api/campaign-submission-count?id=${el.id}`,
      }).then(function(response){
        console.log('posted ok =========================================== ')
      }).catch(function (error) {
        console.log('error posted =========================================== ')
      });

      return (
        <tr key={index}>
          <td>
            <p>
              <strong>{el.data.campaign_name}</strong>{' '}
              <span className={'tag ' + status_tag}>{status_txt}</span>
              <br />
              {start_date}
              &nbsp;&nbsp;
              <strong>-</strong>
              &nbsp;&nbsp;
              {end_date}
              {/* &nbsp;&nbsp; EST */}
            </p>
          </td>
          <td>
            <SubmissionCount id={el.id} />
          </td>
          <td>
            <Link to={'/campaign/' + el.id} className="button">
              Edit <IconWidget icon="fa-long-arrow-right" />{' '}
            </Link>
          </td>
        </tr>
      );
    });

    return (
      <div className="container">
        <div className="row container-margin">
          <div className="column twelve align-left">
            <Link className="button secondary" to={'/campaign-new'}>
              <IconWidget icon="fa-plus-square" /> New Campaign
            </Link>
            &nbsp;&nbsp;
            <button onClick={this.handleClick}>
              <IconWidget icon="fa-folder" /> {this.state.showArchivedText}
            </button>
            <div className="pull-right">
              <Link className="button secondary" to={'/settings'}>
                <IconWidget icon="fa-cog" />
                {' Settings'}
              </Link>
            </div>
          </div>
        </div>

        <section id="page-wrap" className="">
          <article>
            <div className="row full-width">
              <div className="card column twelve">
                <table>
                  <tbody>
                    <tr>
                      <td>Campaign Name</td>
                      <td>Submissions</td>
                      <td>Actions</td>
                    </tr>
                    {Accounts}
                  </tbody>
                </table>
                <div className={this.state.loadingState}>
                  <LoadingIcon size="80" />
                  <br />
                  {this.state.loadingMessage}
                </div>
                <div className={this.state.has_no_data}>
                  {this.state.has_no_data_message}
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
    );
  }
}
export default Campaigns;
