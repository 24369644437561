import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { randyShuffle, camelizeSnake, flip } from './utils';
import Pagination from 'react-js-pagination';
import zipcodes from 'zipcodes';
import moment from 'moment';
import Auth from './Auth';
import Config from './Config';
import IconWidget from './_widget_icon';
import fire from './_fire';
import normalizeEmail from './email-normalize';
import { isArray, chunk, flatten } from 'lodash';

import './App.css';
import './uptown.css';
import './Account.css';
import './MenuSide.css';

const normal = flip(normalizeEmail).bind(null, {
  forceRemoveDots: true,
  forceRemoveTags: true,
});

const normalStr = str =>
  str
    .normalize()
    .trim()
    .toUpperCase();

class CampaignSubmissions extends Component {
  static locations = ['brooklyn', 'los-angeles', 'miami', 'soho'];
  static blacklist = [
    '+',
    '@qq',
    'cacheemall',
    'soleceroralex',
    '.club',
    'cryptokingmail',
    'hacks',
    '.win',
    '.loan',
    '.science',
    '.me',
    'blkhorze',
    'snkrtitan',
    'higherbwgm',
    '.site',
    '.party',
    '.space',
    '.info',
    '.download'
  ];

  static ips = [
    '2604:2000:50d8:8800:cda6:2ecc:99c7:92f1',
    '96.246.26.109',
    '67.86.227.213',
    '173.68.196.145',
  ]

  static weightStrategy({ email, zip, last_order }) {
    const val = !!email + !!zip + !!last_order;

    const dictionary = {
      0: 'rejected',
      1: 'red',
      2: 'orange',
      3: 'green',
    };

    return function(fn, ...rest) {
      return fn(dictionary[val], ...rest);
    };
  }

  static weightValue(tag, state = {}) {
    const { weights } = state.settings || {};
    return weights[tag];
  }

  constructor(props) {
    super(props);

    var winners_notified = this.props.match.params.winners_notified;
    if (winners_notified == 'undefined') {
      winners_notified = 'false';
    }

    this.state = {
      submissions: [],
      campaignId: this.props.match.params.id,
      winnersNotified: 'winner-' + winners_notified,
      campaignName: '',
      startDate: '',
      endDate: '',
      maxEntries: '',
      campaignProducts: [],
      campaignCollection: '',
      winnerCount: '',
      oneEntryPerEmail: true,
      limitByIp: true,
      agreeToTerms: false,
      submissionCount: 0,
      dataType: 'all',
      showLoading: 'hide-loading',
      showButton: 'hide-button',
      showForm: 'show-form',
      showThankYou: 'hide-thank-you',
      token_id: '',
      activePage: 1,
      dataCount: 0,
      winBtn: 'show-me',
      allBtn: 'hide-me',
      settings: {},
      winners: {},
      waitlisted: {},
      winnersResults: [],
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleShowWinners = this.handleShowWinners.bind(this);
    this.notifyWinners = this.notifyWinners.bind(this);
    this.promoteSubmission = this.promoteSubmission.bind(this);
    this.generateWinners = this.generateWinners.bind(this);
    this.get_shop_settings = this.get_shop_settings.bind(this);
    this.mapRecordsToState = this.mapRecordsToState.bind(this);
    this.weightValue = this.weightValue.bind(this);
    this.onPromoteSubmission = this.onPromoteSubmission.bind(this);
    this.demoteSubmission = this.demoteSubmission.bind(this);
    this.onDemoteSubmission = this.onDemoteSubmission.bind(this);
    this.submissionFactory = this.submissionFactory.bind(this);
    this.getPreviousWinners = this.getPreviousWinners.bind(this);
    this.filterSubmissions = this.filterSubmissions.bind(this);
    this.assignWeightToSubmissions = this.assignWeightToSubmissions.bind(this);
  }

  componentDidMount() {

    this.get_submission_data(1);
    this.get_submission_count();
    this.get_shop_settings();
    this.getPreviousWinners();
    this.get_campaign(this.props.match.params.id);

    // Get current winners and waitlisted
    this.mapRecordsToState('winners', ['winners']);
    this.mapRecordsToState('waitlisted', ['waitlisted']);
    CampaignSubmissions.locations.forEach(location => {
      this.mapRecordsToState('winners', [location, 'results', 'winners'])
      this.mapRecordsToState('waitlisted', [location, 'results', 'waitlisted'])
    })
  }

  get_shop_settings() {
    const ref = fire
      .firestore()
      .collection('settings')
      .doc('kithnyc.myshopify.com');
    return ref
      .get()
      .then(doc => (doc.exists ? doc.data() : {}))
      .then(camelizeSnake)
      .then(settings => this.setState({ settings }));
  }

  mapRecordsToState(category, references) {
    references = isArray(references) ? references : [references]
    const { campaignId } = this.state;
    const ref = fire
      .firestore()
      .collection('campaigns')
      .doc(campaignId)

    const query = references.reduce((acc, reference, i) => {
      const method = i % 2 === 0 ? 'collection' : 'doc'
      return acc[method](reference)
    }, ref)

    return query
      .get()
      .then(snap => {
        return snap.docs.reduce((a, doc) => {
          const data = camelizeSnake(doc.data())
          const { id } = data;
          a[id] = data;
          return a;
        }, {})
      })
      .then(result => {
        const stateChanges = prevState => {
          return {
            [category]: {
              ...prevState[category],
              ...result,
            },
          };
        };

        return this.setState(stateChanges);
      });
  }

  get_submission_count() {
    var self = this;
    if (this.state.dataType === 'winners') {
    } else {
      var submissionRef = fire
        .database()
        .ref('submissions/' + this.state.campaignId);
      submissionRef.on('value', function(snapshot) {
        var submission = snapshot.val();
        if (submission && submission.submission_count) {
          self.setState({
            dataCount: submission.submission_count,
          });
        }
      }); // end
    }
  }

  get_submission_data(page_id) {
    var self = this;
    if (this.state.dataType === 'winners') {
      var xref = fire
        .firestore()
        .collection('campaigns')
        .doc(this.state.campaignId)
        .collection('winners')
        .get()
        .then(function(querySnapshot) {
          var docs = [];
          querySnapshot.forEach(function(doc) {
            var dataUser = doc.data();
            const weight = dataUser.weight;
            var data = { ...dataUser, weight, id: doc.id };
            docs.push(data);
          });
          self.setState({
            loadingState: 'hide-loading',
            submissions: docs,
            dataCount: docs.length,
          });
        });
    } else {
      var xref = fire
        .firestore()
        .collection('submissions')
        .where('campaign_id', '==', this.state.campaignId)
        .limit(100);

      var observer = xref.onSnapshot(
        doc => {
          var docs = [];
          doc.forEach(doc => {
            var data = {
              ...doc.data(),
              id: doc.id,
            };
            docs.push(data);
          });

          self.setState({
            loadingState: 'hide-loading',
            submissions: docs,
          });
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
    } // end if
  } // function =========

  getPreviousWinners() {
    return fire
      .firestore()
      .collection('campaigns')
      .get()
      // .then(snap => {
      //   const sorted = snap.docs.map(d => d.data()).sort((left, right) => {
      //     const leftEnd = left.end_date
      //     const rightEnd = right.end_date
      //     const momentDiff = moment(rightEnd).diff(moment(leftEnd))

      //     if (leftEnd === 'Invalid date') {
      //       return 1
      //     }

      //     if (rightEnd === 'Invalid date') {
      //       return -1
      //     }

      //     return momentDiff
      //   }).slice(0, 3)

      //   return sorted.map(s => s.campaign_id)
      // })
      .then(snap =>
        snap.docs.reduce((acc, cur) => {
          const dateFormat = 'YYYY-MM-DDTHH:mm';
          const isCampaign = cur.id === this.state.campaignId;
          const data = cur.data();
          const { end_date } = data;
          const isLte90 =
            moment().diff(moment(end_date, dateFormat), 'days') <= 90;

          return isCampaign && isLte90 ? acc : [...acc, cur.id];
        }, [])
      )
      .then(ids => {
        const ref = fire.firestore().collection('campaigns');
        const promises = ids.reduce((acc, id) => {
          const campaignRef = ref.doc(id);
          // Previous campaigns only had 'winners' collection
          const seed = [campaignRef.collection('winners').get()]
          const innerPromises = CampaignSubmissions.locations.reduce((acc, loc) => {
            return acc.concat(
              campaignRef
              .collection(loc)
              .doc('results')
              .collection('winners')
              .get()
            )
          }, seed)
          return acc.concat(innerPromises)
        }, []);

        return Promise.all(promises)
      })
      .then(snaps => snaps.reduce((acc, snap) => {
        const dataList = snap.docs.map(d => {
          const data = d.data()
          return data.data ? { ...data.data, id: data.id } : data
        })

        return acc.concat(dataList)
      }, []))
      .then(data => this.setState({ previousWinners: data }))
      .catch(console.error);
  }

  filterSubmissions(submissions) {
    console.log('filtering submissions');
    const currentWinnerIds = Object.keys(this.state.winners);
    const { previousWinners } = this.state;
    const metadata = {
      blacklisted: 0,
      currentWinners: 0,
      previousWinners: 0,
      ips: 0,
    }

    const result = submissions.filter(submission => {

      let miami_zip = '33139'
      let soho_zip  = '10012'
      let bk_zip    = '11217'
      let la_zip    = '90069'

      const {
        id,
        email,
        customer_id,
        first_name,
        last_name,
        zip,
        location,
      } = submission;
      const ip = submission.ip || ''
      // Return false as early as possible so we don't have to iterate over the same document multiple times
      if (!email) {
        return false;
      }

      const isBlacklistedIP = CampaignSubmissions.ips.some(
        i => {
          const i2 = i + ', ' + i
          return ip.indexOf(i) !== -1 || ip.indexOf(i2) !== -1
        }
      );

      if(location !== 'online'){
        let location_zip = ''
        switch(location){
            case 'soho':
              location_zip = soho_zip
            break;
            case 'miami':
              location_zip = miami_zip
            break;
            case 'brooklyn':
              location_zip = bk_zip
            break;
            case 'los-angeles':
              location_zip = la_zip
            break;
        }

        let dist = zipcodes.distance(location_zip, zip);
        console.log('location distance: ' + dist)
        if(parseInt(dist) >= 400){
          return false;
        }
      }

      if (isBlacklistedIP) {
        metadata.ips += 1
        return false;
      }

      const isBlacklisted = CampaignSubmissions.blacklist.some(
        b => email.indexOf(b) !== -1
      );

      if (isBlacklisted) {
        metadata.blacklisted += 1
        return false;
      }

      const isAlreadyWinner = currentWinnerIds.some(
        w => id.indexOf(w) !== -1
      );

      if (isAlreadyWinner) {
        metadata.currentWinners += 1
        return false;
      }

      let wasWinner = ''
      if(previousWinners !== undefined){
        wasWinner = previousWinners.some(p => {
          // Because we have dummy winner somewhere...
          if (!p.email || !p.first_name || !p.last_name) {
            return false;
          }
          const sameEmail = normal(p.email) === normal(email);
          const sameName =
            normalStr(p.first_name) === normalStr(first_name) &&
            normalStr(p.last_name) === normalStr(last_name);
          const sameCustomer = p.customer_id === customer_id;
          return sameEmail || sameName || sameCustomer;
        });
      }


      if (wasWinner) {
        metadata.previousWinners += 1
        return false;
      }

      console.log('submission is valid');
      return true;
    });

    return {
      metadata,
      result,
    }
  }

  assignWeightToSubmissions(submissions) {
    return submissions.map(submission => {
      console.log('assigning weight');
      const weight = this.weightValue(submission);
      const weightTag = CampaignSubmissions.weightStrategy(
        submission
      )(x => x);
      const sub = Object.assign({}, submission, {
        weight: weightTag,
      });
      return [sub, weight];
    })
  }

  computeWinners(conditions) {
    return submissions => {
      const limit = parseInt(conditions.total || 0, 10);
      const { result, remaining } = randyShuffle(submissions, 0.5, limit);

      const metadata = {
        limit,
        size: conditions.shoe_size,
        location: conditions.location,
        selectedFrom: submissions.length,
        picked: result.length,
        waitlisted: remaining.length,
        model: conditions.model,
      };

      return {
        winners: result,
        waitlisted: remaining,
        metadata
      }
    }
  }

  generateWinners() {
    var campaignId = this.state.campaignId;
    var winnersNotified = this.state.winners_notified;
    var winnerJson = this.state.winnerJson.trim();
    var metadata_x = JSON.parse(winnerJson || '{}') || [];

    if (winnersNotified) {
      toast.warn(
        'Winners have already been chosen, please delete the current winners'
      );
      return null;
    }

    const winnersResults = [];

    // Cache helper functions
    const getWinnerBatchParams = this.bindPromotionToGroup('winners', {
      is_winner: true,
    });

    const getWaitlistedBatchParams = this.bindPromotionToGroup('waitlisted', {
      is_waitlisted: true,
    });

    toast.success('Picking potential winners');

    const promises = metadata_x.meta_data_json.map(conditions => {
      const { total, waitlist, ...rest } = conditions;
      const keys = Object.keys(rest);
      const submissionRef = fire
        .firestore()
        .collection('submissions')
        .where('campaign_id', '==', campaignId);

      const decoratedSubmissionRef = keys.reduce(
        (a, c) => a.where(c, '==', conditions[c]),
        submissionRef
      );

      return decoratedSubmissionRef
        .get()
        .then(snap => snap.docs.map(d => ({ ...d.data(), id: d.id })))
        .then(this.filterSubmissions)
        .then(({ result }) => this.assignWeightToSubmissions(result))
        .then(this.computeWinners(conditions))
        .then(result => {
          const { winners, waitlisted, metadata } = result
          winnersResults.push(metadata)
          return winners.map(getWinnerBatchParams).concat(waitlisted.map(getWaitlistedBatchParams))
        })
    });

    return Promise.all(promises)
      .then(nestedBatches => {
        toast.success('Saving results to database');
        console.log('Saving', nestedBatches.length, 'batches to database')
        const merged = flatten(nestedBatches)
        console.log('Saving', merged.length, 'total records to database')
        const chunks = chunk(merged, 500);
        return chunks.map(batches => {
          const writeBatch = fire.firestore().batch();
          batches.forEach(batchParam => {
            if (!batchParam[0].firestore) {
              throw new Error('First element must be a document reference');
            }

            writeBatch.set(...batchParam);
          });

          return writeBatch.commit();
        });
      })
      .then(() => {
        const stateChanges = prevState => ({
          winnersResults: [...prevState.winnersResults, winnersResults],
        });
        return this.setState(stateChanges, () =>
          toast.success('Winner(s) choosen')
        );
      })
      .catch(console.error);
  }

  bindPromotionToGroup(category = 'winners', fields = {}) {
    return submission => {
      const { campaignId } = this.state;
      const { id, location } = submission;
      const updates = {
        ...submission,
        ...fields,
        has_confirmed: false,
      };
      const ref = fire
        .firestore()
        .collection('campaigns')
        .doc(campaignId)
        .collection(location)
        .doc('results')
        .collection(category)
        .doc(id);

      return [ref, updates];
    };
  }

  promoteSubmission(category = 'winners', fields = {}) {
    return submission => {
      const { id } = submission;
      const [submissionRef, submissionUpdates] = this.bindPromotionToGroup(
        category,
        fields
      )(submission);

      const stateChanges = prevState => ({
        [category]: { ...prevState[category], [id]: submissionUpdates },
      });

      console.log('promoting...', submission.first_name);
      return submissionRef
        .set(submissionUpdates)
        .then(() => this.setState(stateChanges))
        .then(() => submission);
    };
  }

  onPromoteSubmission(...params) {
    return submissionData => {
      return this.promoteSubmission(...params)(submissionData).then(() =>
        toast.success('Winner choosen')
      );
    };
  }

  demoteSubmission(collection = 'winners') {
    return submissionData => {
      const { campaignId } = this.state;
      const { id } = submissionData;
      const submissionRef = fire
        .firestore()
        .collection('campaigns')
        .doc(campaignId)
        .collection(collection)
        .doc(id);
      const stateChanges = prevState => ({
        winners: { ...prevState.winners, [id]: null },
        waitlisted: { ...prevState.waitlisted, [id]: null },
      });
      return submissionRef.delete().then(() => this.setState(stateChanges));
    };
  }

  onDemoteSubmission(collection) {
    return submission => {
      const subject = collection === 'waitlisted' ? 'Waitlisted' : 'Winner';
      return this.demoteSubmission(collection)(submission).then(() =>
        toast.success(`${subject} removed`)
      );
    };
  }

  notifyWinners() {
    return fire
      .firestore()
      .collection('campaigns')
      .doc(this.state.campaignId)
      .update({ winners_notified: true })
      .then(() => {
        return this.setState({ winners_notified: true });
      });
  }

  handleShowWinners() {
    if (this.state.dataType === 'all') {
      console.log('a');
      this.setState(
        {
          winBtn: 'hide-me',
          allBtn: 'show-me',
          dataType: 'winners',
        },
        () => {
          this.get_submission_data(1);
          this.get_submission_count();
        }
      );
    } else {
      console.log('b');
      this.setState(
        {
          winBtn: 'show-me',
          allBtn: 'hide-me',
          dataType: 'all',
        },
        () => {
          this.get_submission_data(1);
          this.get_submission_count();
        }
      );
    }
  }

  handlePageChange(pageNumber) {
    this.get_submission_data(pageNumber);
    this.setState({
      activePage: pageNumber,
    });
  }

  get_campaign(campaign_id) {
    var campaignRef = fire
      .firestore()
      .collection('campaigns')
      .doc(campaign_id);
    return campaignRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
          return null;
        }

        const data = doc.data();
        this.setState({
          campaignId: data.campaign_id,
          winners_notified: data.winners_notified,
          winnersGenerated: data.winners_generated,
          winnerJson: data.winner_json_settings,
        });
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
  }

  renderWeightTag(tag) {
    return <span className={'tag ' + tag}>{tag}</span>;
  }

  weightValue(data) {
    return CampaignSubmissions.weightStrategy(data)(
      CampaignSubmissions.weightValue,
      this.state
    );
  }

  submissionFactory(el) {
    const { winners = {}, waitlisted = {} } = this.state;
    const winner = winners[el.id];
    const waitlist = waitlisted[el.id];

    let submissionStatus = 'def';

    if (waitlist) {
      submissionStatus = 'waitlisted';
    }

    if (winner) {
      submissionStatus =
        winner && winner.hasConfirmed ? 'confirmedWinner' : 'unconfirmedWinner';
    }

    return (status =>
      ({
        confirmedWinner: {
          user_tag: 'green',
          user_tag_txt: 'winner',
          user_btn_txt: 'Remove winner',
          user_icon: 'fa fa-trophy',
          user_btn_ico: 'fa fa-times',
          user_btn_class: 'primary',
          boundAction: this.onDemoteSubmission('waitlisted').bind(this, el),
        },
        unconfirmedWinner: {
          user_tag: 'yellow',
          user_tag_txt: 'pending',
          user_btn_txt: 'Remove Winner',
          user_icon: 'icon-question',
          user_btn_ico: 'fa fa-times',
          user_btn_class: 'primary',
          boundAction: this.onDemoteSubmission('winners').bind(this, el),
        },
        waitlisted: {
          user_tag: 'blue',
          user_tag_txt: 'waitlisted',
          user_btn_txt: 'Remove waitlisted',
          user_icon: 'icon-post',
          user_btn_ico: 'fa fa-times',
          user_btn_class: 'link',
          boundAction: this.onDemoteSubmission('waitlisted').bind(this, el),
        },
        def: {
          user_tag: 'grey',
          user_tag_txt: 'nope',
          user_btn_txt: 'Make Winner',
          user_icon: 'fa fa-user',
          user_btn_ico: 'fa fa-plus',
          user_btn_class: 'secondary',
          boundAction: this.onPromoteSubmission('winners').bind(this, el),
        },
      }[status]))(submissionStatus);
  }

  render() {
    var token = Auth.getToken();
    const Submissions = this.state.submissions.map((el, index) => {
      var metadata = '';

      if (el.location) {
        metadata += 'Location: ' + el.location + ' ';
      }

      if (el.shoe_size) {
        metadata += 'Shoe Size: ' + el.shoe_size + ' ';
      }

      if (el.gender) {
        metadata += 'Gender: ' + el.gender + ' ';
      }

      if (el.shirt_size) {
        metadata += 'Top: ' + el.shirt_size + ' ';
      }

      if (el.pant_size) {
        metadata += 'Bottom: ' + el.pant_size + ' ';
      }

      if (el.model) {
        metadata += 'Model: ' + el.model + ' ';
      }

      const submission = this.submissionFactory(el);
      const {
        user_tag,
        user_tag_txt,
        user_btn_class,
        boundAction,
        user_btn_ico,
        user_btn_txt,
        user_icon,
      } = submission;
      const weightTag = CampaignSubmissions.weightStrategy(el)(
        this.renderWeightTag
      );

      return (
        <tr key={index}>
          <td>{weightTag}</td>
          <td className="align-center">
            <div className={'tag ' + user_tag}>
              <span className="SubmissionTable-status">
                <IconWidget icon={user_icon} />
                <div>{user_tag_txt}</div>
              </span>
              {/* {winner && <div class="SubmissionTable-confirmed">{confirmText}</div>} */}
            </div>
          </td>
          <td>
            <strong>
              {el.first_name} {el.last_name}
            </strong>
            <br />
            {el.email}
            <br />
            {metadata}
          </td>
          <td>
            <a
              href={Config.shopify_admin + '/admin/customers/' + el.customer_id}
              className="button secondary"
              target="_blank"
            >
              <IconWidget icon="fa-shopping-bag" /> View Shopify Account
            </a>
          </td>
          <td>
            <button className={user_btn_class} onClick={boundAction}>
              <IconWidget icon={user_btn_ico} /> {user_btn_txt}
            </button>
          </td>
        </tr>
      );
    });

    const GenerationResults = () => {
      if (!this.state.winnersResults.length) {
        return null;
      }

      return (
        <div className="row container-margin">
          <div className="column twelve">
            <article>
              <div className="card has-sections">
                <div className="card-section">Winner Results</div>

                <div className="card-section">
                  <pre
                    style={{
                      padding: '2em',
                      maxHeight: '600px',
                      overflow: 'scroll',
                      textAlign: 'left',
                    }}
                  >
                    {JSON.stringify(this.state.winnersResults, null, 2)}
                  </pre>
                </div>
              </div>
            </article>
          </div>
        </div>
      );
    };

    return (
      <div className="content-wrapper-sidebar container">
        <div className="row container-margin">
          <div className="column twelve align-left CampaignSubmission__ActionButtons">
            <Link
              to={'/campaign/' + this.state.campaignId}
              className="button secondary"
            >
              <IconWidget icon="fa-long-arrow-left" /> Back
            </Link>
            &nbsp;&nbsp;
            <button onClick={this.notifyWinners} className="secondary">
              <IconWidget icon="fa-lightbulb-o" /> Notify Winners
            </button>
            &nbsp;&nbsp;
            <button onClick={this.generateWinners} className="secondary">
              <IconWidget icon="fa-random" /> Generate Random Winners
            </button>
            &nbsp;&nbsp;
            <button
              className={'button secondary button-100x ' + this.state.winBtn}
              onClick={this.handleShowWinners}
            >
              <IconWidget icon="fa-trophy" /> View Winners
            </button>
            <button
              className={'button secondary button-100x ' + this.state.allBtn}
              onClick={this.handleShowWinners}
            >
              <IconWidget icon="fa-user" /> View All
            </button>
            &nbsp;&nbsp;
            <a
              href={
                '/api/campaign-csv-download/' +
                this.state.campaignId +
                '/' +
                this.state.dataType +
                '/?token=' +
                token
              }
              className="button button-100x"
              target="_blank"
            >
              <IconWidget icon="fa-cloud-download" /> Download{' '}
              <u>{this.state.dataCount}</u> Submissions to CSV
            </a>
            &nbsp;&nbsp;
            <a
              href={
                '/api/campaign-csv-download/' +
                this.state.campaignId +
                '/winners/?token=' +
                token
              }
              className="button button-100x"
              target="_blank"
            >
              <IconWidget icon="fa-cloud-download" /> Download winners to CSV
            </a>
          </div>
        </div>

        {GenerationResults()}

        <div id="outer-container" style={{ height: '100%' }}>
          <section id="page-wrap" className="container">
            <article>
              <div className="card">
                <div className="align-right">
                  <div className={this.state.winnersNotified}>
                    <div className="alert warning">
                      <dl>
                        <dt>Winners have been notified</dt>
                        <dd>
                          If you generate new winners this will remove winner
                          status from current winners.
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>

                <table className="SubmissionTable">
                  <tbody>
                    <tr>
                      <td>Weight</td>
                      <td>Status</td>
                      <td>Info</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {Submissions}
                  </tbody>
                </table>
                <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={25}
                  totalItemsCount={this.state.dataCount}
                  pageRangeDisplayed={15}
                  onChange={this.handlePageChange}
                />
              </div>
            </article>
          </section>
        </div>
      </div>
    );
  }
  // end
} // end component
export default CampaignSubmissions;
