import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './App.css';
import './uptown.css';

class AccountNavigation extends Component {

  componentDidMount(){
    // grab session from redis
  }

  render() {
    return (
        <ul>
          <li><Link to="/campaigns">All Campaigns</Link></li>
          <li><Link to="/campaign-new">New Campaign</Link></li>
          <li><Link to="/account/new">Submit Entry</Link></li>
        </ul>
    );
  }
}
export default AccountNavigation;
