import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Auth from './Auth';
import Config from './Config';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import './App.css';
import './uptown.css';
import './Account.css';

class CampaignProducts extends Component {
  // start
    constructor(props) {
      super(props);
      this.state = {
          campaignId: this.props.match.params.id,
          dataCount: 0,
          data: [],
          activePage: 1,
          showLoading: 'hide-loading',
          showButton: 'hide-button',
          showForm: 'show-form',
          showThankYou: 'hide-thank-you',
          token_id: ''
      };
      this.handlePageChange         = this.handlePageChange.bind(this);
    }

    get_product_data(page_id){
      toast.info("Loading products for page "+page_id);
      var token = Auth.getToken();
      console.log('get campaign');
      axios.get(Config.seed_api_url+'/products/get/6/'+page_id+'/20/?token='+token)
          .then(({ data })=> {
            if(data === 'no results'){
              console.log('x');
              this.setState({
                loadingState:'hide-loading',
                data: [],
                has_no_data: 'no-data'
              });
              console.log(this.state)
            }else if(data){
              console.log(data['products'])
              console.log('GO DATA')
              this.setState({
                loadingState:'hide-loading',
                data: data['products']
              });
              toast.success("Products loaded for page "+page_id);
              console.log(this.state.data);
            }else{
              console.log('z');
              this.setState({
                loadingState:'hide-loading',
                data: [],
                has_no_data: 'no-data'
              });
            }

          }).catch((err)=> {})
    }

    handlePageChange(pageNumber) {
      console.log(`active page is ${pageNumber}`);
      this.get_product_data(pageNumber);
      this.setState({
        activePage: pageNumber
      })
    }


    saveProduct(item, event) {
      event.preventDefault();

      this.setState({
        showButton: 'hide-button',
        showLoading: 'show-loading'
      });

      var data  = new FormData();
      var token = Auth.getToken();
      data.append('campaignId', this.state.campaignId);
      data.append('productId', item.id);
      data.append('productHandle', item.handle);
      data.append('productTitle', item.title);
      data.append('productDescription', item.body_html);

      if(item.images[0]){
        data.append('productImage', item.images[0].src);
      }else{
        data.append('productImage', '');
      }

      data.append('token', token);

      const self = this;
      toast.info("Saving product "+item.title);
      axios.post(Config.api_url+'/api/campaign/save-product', data)
        .then(function (res) {
          console.log(res.data);
          self.setState({
            showForm: 'hide-form',
            showThankYou: 'show-thank-you'
          });
          toast.info("Saved product "+item.title);
          self.props.history.push('/campaign/'+self.state.campaignId+'?product-save=yes');
        })
        .catch(function (err) {
          console.log(err);
          self.setState({
            showButton: 'show-button',
            showLoading: 'hide-loading'
          });
          toast.error("Error saving product "+item.title);
        });
    } // save function =========================================================

    componentDidMount(){
      this.get_product_data(1)
      var token = Auth.getToken();
      console.log(Config)
      axios.get(Config.seed_api_url+'/products/get-count/6/?token='+token)
          .then(({ data })=> {
            if(data === 'no results'){
              console.log('x');
              this.setState({
                loadingState:'hide-loading',
                data: [],
                has_no_data: 'no-data'
              });
              console.log(this.state)
            }else if(data){
              this.setState({
                loadingState:'hide-loading',
                dataCount: data['count']
              });
              console.log('COUNT'+data['count']);
            }else{
              console.log('z');
              this.setState({
                loadingState:'hide-loading',
                data: [],
                has_no_data: 'no-data'
              });
            }

          }).catch((err)=> {})
      // grab session from redis
    }


    render() {



      const productsx = this.state.data.map((el, index) => {
        let boundSaveProduct = this.saveProduct.bind(this, el);
        var image;
        if(el.images[0]){
          image = el.images[0].src
        }else{
          image = 'no image'
        }

        return <tr key={index} className="fadeIn">
             <td>
                <img alt="" src={image} className="product-thumbnail" />
              </td>
              <td>{el.title}</td>
              <td><a href="" onClick={boundSaveProduct} className="button">Use This Product</a></td>
            </tr>

      });



      return (
        <div className="content-wrapper-sidebar">

              <div className="side-bar">
                <Link to={"/campaign/"+this.state.campaignId} className="button">BACK</Link>
              </div>
              <div className="content-container">


                <div className={this.state.showThankYou}>
                  <h1>Thank You.</h1>
                </div>

                <div className={this.state.showForm}>
                      <div>
                        <div>
                          <h2>Products</h2>
                        </div>
                        <table>
                          <tbody>
                            <tr>
                              <td>Image</td>
                              <td>Title</td>
                              <td>&nbsp;</td>
                            </tr>
                            {productsx}
                          </tbody>
                        </table>
                      </div>

                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={20}
                        totalItemsCount={this.state.dataCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />

                  </div>
              </div>
          </div>
        );
      }
  // end
}// end component
export default CampaignProducts;
