import React from 'react';
import SettingsControl from './SettingsControl';
import fire from './_fire';
import { camelizeSnake, snakifyCamel } from './utils';

class Settings extends React.Component {
  static ref = fire.firestore().collection('settings').doc('kithnyc.myshopify.com');

  constructor() {
    super();
    this.state = {};
    this.saveSettings = this.saveSettings.bind(this);
  }

  componentDidMount() {
    return Settings.ref
      .get()
      .then(doc => (doc.exists ? doc.data() : {}))
      .then(settings => {
        this.setState(camelizeSnake(settings));
      })
      .catch(console.error);
  }

  saveSettings(settings) {
    return Settings.ref
      .set(snakifyCamel(settings))
      .then(() => this.setState(settings))
      .catch(console.error);
  }

  render() {
    return (
      <SettingsControl
        {...this.props}
        settings={this.state}
        saveSettings={this.saveSettings}
        title="Settings"
      />
    );
  }
}

export default Settings;
