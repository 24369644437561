import React, { Component } from 'react';
import axios from 'axios';
import AccountNavigation from './AccountNavigation';
import Config from './Config';

import './App.css';
import './uptown.css';
import './Account.css';

class AccountNew extends Component {
  // start
    constructor(props) {
      super(props);
      this.state = {
          firstName: '',
          lastName: '',
          shoeSize: '',
          email: '',
          location: '',
          customerId: '6693992391',
          campaignId: '2',
          agreeToTerms: false,
          showLoading: 'hide-loading',
          showButton: 'hide-button',
          showForm: 'show-form',
          showThankYou: 'hide-thank-you',
          token_id: ''
      };

      this.handleChange             = this.handleChange.bind(this);
      this.handleSubmit             = this.handleSubmit.bind(this);
    }

    handleChange(event) {
      const target  = event.target;
      console.log(event.target.name)
      const value   = target.type === 'checkbox' ? target.checked : target.value;
      const name    = target.name;

      if(target.type === 'checkbox'){
        if(target.checked){
          this.setState({
            showButton: 'show-button'
          });
        }else{
          this.setState({
            showButton: 'hide-button'
          });
        }
      }

      this.setState({
        [name]: value
      });

    }

    handleSubmit(event) {
      event.preventDefault();

      this.setState({
        showButton: 'hide-button',
        showLoading: 'show-loading'
      });

      var data = new FormData();

      data.append('email', this.state.email);
      data.append('firstName', this.state.firstName);
      data.append('lastName', this.state.lastName);
      data.append('shoeSize', this.state.shoeSize);
      data.append('location', this.state.location);
      data.append('agreeToTerms', this.state.agreeToTerms);
      data.append('customerId', this.state.customerId);
      data.append('campaignId', this.state.campaignId);

      console.log(data);
      const self = this;
      axios.post(Config.api_url+'/api/save-entry/', data)
        .then(function (res) {
          console.log(res.data);
          self.setState({
            showForm: 'hide-form',
            showThankYou: 'show-thank-you'
          });
        })
        .catch(function (err) {
          console.log(err);
          self.setState({
            showButton: 'show-button',
            showLoading: 'hide-loading'
          });
        });

    }

    render() {
      return (
        <div className="content-wrapper-sidebar">

              <div className="side-bar">
                <AccountNavigation />
              </div>
              <div className="content-container">


                <div className={this.state.showThankYou}>
                  <h1>Thank You.</h1>
                </div>

                <div className={this.state.showForm}>

                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          <div className="column twelve">
                            Email<br />
                            <input type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="column six">
                            First Name<br />
                          <input type="text" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                          </div>
                          <div className="column six">
                            Last Name<br />
                          <input type="text" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="column six">
                            Shoe Size<br />
                          <input type="text" name="shoeSize" value={this.state.shoeSize} onChange={this.handleChange} />
                          </div>
                          <div className="column six">
                            Location<br />
                          <input type="text" name="location" value={this.state.location} onChange={this.handleChange} />
                          </div>
                        </div>
                      <div className="row">

                          <input
                            name="agreeToTerms"
                            type="checkbox"
                            checked={this.state.agreeToTerms}
                            onChange={this.handleChange} />

                          I agree to the <a href="https://google.com">terms</a>
                        </div>
                        <div className={"row " + this.state.showButton}>
                          <input type="submit" value="Submit" />
                        </div>
                        <div className={this.state.showLoading}>
                          <img alt='/loading-content.gif' src="/loading-content.gif" />
                        </div>
                      </form>
                  </div>
              </div>
          </div>
        );
      }
  // end
}// end component
export default AccountNew;
