import React from 'react'
import OptionControl from './OptionControl'
import IconWidget from '../_widget_icon'
import { isEqual, noop, snakeCase } from 'lodash'

class OptionContainer extends React.Component {
  static defaultProps = {
    onOptionsUpdate: noop,
    options: {},
    title: 'Options',
    addText: 'Add Option',
  }

  static generateKey(prefix = 'option') {
    return [prefix, new Date().getTime().toString(16)].join('_')
  }

  static getDerivedStateFromProps(props, state) {
    if (isEqual(props.options, state.options)) {
      return null
    }

    if (state.fromState) {
      return {
        optionIDs: Object.keys(state.options),
        options: state.options,
        fromState: false,
      }
    }

    return {
      optionIDs: Object.keys(props.options),
      options: props.options,
    }
  }

  constructor(props) {
    super(props)
    this.handleOptionSave = this.handleOptionSave.bind(this)
    this.handleOptionRemove = this.handleOptionRemove.bind(this)
    this.handleEditOpen = this.handleEditOpen.bind(this)
    this.handleEditClose = this.handleEditClose.bind(this)
    this.renderOptionControl = this.renderOptionControl.bind(this)
    this.handleNewOptionSave = this.handleNewOptionSave.bind(this)
    this.renderNewOption = this.renderNewOption.bind(this)

    this.state = {
      options: this.props.options,
      optionIDs: Object.keys(this.props.options),
      optionsInEdit: [],
      newOptionVisible: false,
      fromState: false,
    }
  }

  // Send updates
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.options, this.state.options)) {
      this.props.onOptionsUpdate(
        this.state.options,
        this.props.id,
        this.props.title
      )
    }
  }

  handleOptionSave(option, id) {
    if (!id) {
      return null
    }

    id = id[0].toLowerCase() + id.substr(1)

    const { options, optionsInEdit } = this.state

    const updatedOption = {
      title: option.title,
      values: option.values,
    }

    const nextOptions = {
      ...options,
      [id]: { title: updatedOption.title, values: updatedOption.values },
    }

    if (isEqual(nextOptions, options)) {
      return null
    }

    const payload = {
      options: nextOptions,
      optionIDs: Object.keys(nextOptions),
      optionsInEdit: optionsInEdit.filter((opt) => opt !== id),
      fromState: true,
    }

    this.setState(payload)
  }

  handleOptionRemove(id) {
    const stateChanges = (prevState) => {
      const {
        options: { [id]: _, ...options },
        optionIDs,
      } = prevState

      return {
        options,
        optionIDs: optionIDs.filter((opt) => opt !== id),
        optionsInEdit: optionIDs.filter((opt) => opt !== id),
      }
    }

    this.setState(stateChanges)
  }

  handleEditOpen(id) {
    const stateChanges = (prevState) => ({
      optionsInEdit: [...prevState.optionsInEdit, id],
    })

    this.setState(stateChanges)
  }

  handleEditClose(id) {
    const stateChanges = (prevState) => ({
      optionsInEdit: prevState.optionsInEdit.filter((oid) => oid !== id),
    })

    this.setState(stateChanges)
  }

  handleNewOptionSave(option) {
    this.handleOptionSave(option, option.title)
    this.setState({ newOptionVisible: false })
  }

  mapOptionsToComponents(props, state) {
    const { options, optionIDs } = state
    const { singleValue, boxCount } = props

    return optionIDs.map((key) => {
      const optionProps = {
        ...options[key],
        singleValue,
        boxCount,
      }

      return this.renderOptionControl(optionProps, key)
    })
  }

  renderOptionControl(params, key) {
    const { editMode, ...option } = params
    return (
      <OptionControl
        {...option}
        key={key}
        id={key}
        editMode={this.state.optionsInEdit.indexOf(key) !== -1}
        onEditOpen={this.handleEditOpen}
        onEditClose={this.handleEditClose}
        onOptionRemove={this.handleOptionRemove}
        onOptionSave={this.handleOptionSave}
      />
    )
  }

  renderNewOption() {
    if (this.props.preventNew) {
      return null
    }

    if (this.state.newOptionVisible) {
      return (
        <OptionControl
          singleValue={this.props.singleValue}
          editMode={true}
          onEditClose={() => this.setState({ newOptionVisible: false })}
          onOptionRemove={() => this.setState({ newOptionVisible: false })}
          onOptionSave={this.handleNewOptionSave}
        />
      )
    }

    return (
      <div className="row">
        <button
          className="secondary button-100"
          type="button"
          onClick={() => this.setState({ newOptionVisible: true })}
        >
          <IconWidget icon="fa-plus-circle" />
          {' ' + this.props.addText}
        </button>
      </div>
    )
  }

  render() {
    const options = this.mapOptionsToComponents(this.props, this.state)
    return (
      <div className="row">
        <p>{this.props.title}</p>
        <hr className="row" />
        {options}
        {this.renderNewOption()}
      </div>
    )
  }
}

export default OptionContainer
