import React, { Component } from 'react';

import './App.css';
import './uptown.css';

class AccessDenied extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){

  }

  render() {

    return (
      <div className="content-wrapper-sidebar">

          <div className="side-bar">

          </div>
          <div className="content-container">

            <div>
              <h1>ACCESS DENIED</h1>
              <p>Please login through your shopify app.</p>
            </div>

          </div>

      </div>
    );
  }
}
export default AccessDenied;
