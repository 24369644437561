import React from 'react'
import Box from './Box'

class Models extends React.Component {
  static defaultProps = {
    title: '',
    image: '',
    idx: '',
  }

  // handleTitleChange = (value, id) => {
  //   const { onTitleChange } = this.props
  //   if (onTitleChange) {
  //     onTitleChange(value, id)
  //   }
  // }

  // handleImageChange = (value, id) => {
  //   const { handleImageChange } = this.props
  //   if (handleImageChange) {
  //     handleImageChange(value, id)
  //   }
  // }

  handleChange = (value, title, idx) => {
    if (this.props.onChange) {
      this.props.onChange(value, title, idx)
    }
  }

  render() {
    const { image, title, idx } = this.props

    const boxMarkup = (
      <React.Fragment>
        <hr className="row" />
        <Box
          idx={idx}
          title={'Title'}
          index={idx + 1}
          value={title}
          onChange={this.handleChange}
        />
        <Box
          idx={idx}
          title={'Image'}
          index={idx + 1}
          value={image}
          onChange={this.handleChange}
        />
      </React.Fragment>
    )

    return boxMarkup
  }
}

export default Models
