import firebase from "firebase/app";
require("firebase/firestore");
require("firebase/database");
require("firebase/storage");
require("firebase/auth");

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

var fire      = firebase.initializeApp(config);
var provider  = new firebase.auth.GoogleAuthProvider();
var auth      = firebase.auth();

function signInWithPopup(authProvider) {
  return auth
    .signInWithPopup(authProvider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log(result.user);
      // ...
    })
    .catch(error => {
      // Handle Errors here.
      console.log(error);
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
}

firebase.firestore().settings({})
auth.onAuthStateChanged(user => !user && signInWithPopup(provider));

export { firebase }
export default fire;
