import React from 'react';
import Tag from './Tag';
import TagInput from './TagInput';

class TagBox extends React.Component {
  static ACTIONS_BY_KEY_CODE = {
    13: 'ADD',
    188: 'ADD',
    9: 'ADD',
    8: 'REMOVE',
  }

  static KEY_CODES_BY_NAME = {
    ENTER: 13,
    COMMA: 188,
    TAB: 9,
    BACKSPACE: 8,
  }

  constructor(props) {
    super(props);

    this.handleTagBoxClick = this.handleTagBoxClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.inputRef = this.props.inputRef || React.createRef();
    this.state = {
      focused: false,
      value: '',
      error: '',
    };
  }

  handleTagBoxClick() {
    this.inputRef.current.focus();
  }

  handleFocus() {
    this.setState({ focused: true });
  }

  handleBlur() {
    this.setState({ focused: false });
  }

  handleKeyDown(e) {
    const { which } = e;
    const { value } = this.state;
    const action = TagBox.ACTIONS_BY_KEY_CODE[which];
    const skipAction = which === TagBox.KEY_CODES_BY_NAME.BACKSPACE && value;

    if (!action || skipAction) {
      return null;
    }

    e.preventDefault();
    return action === 'ADD' ? this.addTag(value) : this.removeTag(value);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({ value, error: '' });
  }

  removeTag(tag) {
    const tagToRemove = !tag
      ? this.props.tags[this.props.tags.length - 1]
      : tag;
    return this.props.onTagRemove(tagToRemove);
  }

  addTag(title) {
    const tag = title.trim();

    if (!tag) {
      return this.props.onSave();
    }

    const { tags } = this.props;

    if (tags.indexOf(tag) !== -1) {
      return this.setState({
        error: 'Tag already exists!',
      });
    }

    this.setState({ value: '' });
    return this.props.onTagAdd(tag);
  }

  render() {
    const { tags } = this.props;
    const inputPlaceholder = tags.length ? null : 'Values';
    const tagBoxClassName = this.state.focused
      ? 'TagBox TagBox--focus row'
      : 'TagBox row';

    return (
      <React.Fragment>
        <ul className={tagBoxClassName} onClick={this.handleTagBoxClick}>
          {tags.map((tag, i) => (
            <li key={tag + '_' + i}>
              <Tag title={tag} onClick={this.removeTag} />
            </li>
          ))}

          <li>
            <TagInput
              type="text"
              name="values"
              placeholder={inputPlaceholder}
              autoComplete="off"
              className="TagBox__Input"
              value={this.state.value}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.inputRef}
            />
          </li>
        </ul>
        {this.state.error && (
          <label className="TagBox__Error error">{this.state.error}</label>
        )}
      </React.Fragment>
    );
  }
}

const noop = () => {};
TagBox.defaultProps = {
  onSave: noop,
  onTagAdd: noop,
  onTagRemove: noop,
  tags: [],
};

export default TagBox;
