import React from 'react';
import fire from '../_fire';

const identity = x => x;
class DataProvider extends React.Component {
  static defaultProps = {
    query: [],
    processStrategy: identity
  };

  static strategy = {
    identity,
    DocumentSnapshot: doc => (doc.exists ? doc.data() : {}),
    QuerySnapshot: query => query.docs.map(d => d.data()),
  };

  constructor(props) {
    super(props);
    this.state = { isFetching: true, data: null };
  }

  componentDidMount() {
    return this.fetch(this.props);
  }

  fetch({ query, processStrategy }) {
    const q = query.reduce((a, [method, arg]) => a[method](arg), fire.firestore());

    return q
      .get()
      .then(either => [
        DataProvider.strategy[either.constructor.name] ||
        DataProvider.strategy.identity,
        either,
      ])
      .then(([fn, either]) => fn(either))
      .then(processStrategy)
      .then(data => this.setState({ isFetching: false, data }));
  }

  render() {
    const { isFetching, data } = this.state;
    return this.props.render(data, isFetching);
  }
}

export default DataProvider;
