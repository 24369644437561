import React from 'react';

class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this, this.props.title);
  }

  handleClick(title, e) {
    e.preventDefault();
    e.stopPropagation();
    return this.props.onClick(title);
  }

  render() {
    const { title } = this.props;
    return (
      <span className="tag green remove">
        {title}
        <a href="#" onClick={this.handleClick}>
          <span hidden>{'Remove tag'}</span>
        </a>
      </span>
    );
  }
}

const noop = () => {};
Tag.defaultProps = {
  onClick: noop,
};

export default Tag;
