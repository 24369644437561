import React from 'react'

class Model extends React.Component {

  handleChange = e => {
    const { currentTarget } = e
    const { value } = currentTarget
    const { onChange, title, idx } = this.props
    e.preventDefault()
    if (onChange) {
      onChange(value, title, idx)
    }
  }

  render() {
    const { title, index, value } = this.props

    const imageMarkup = title === 'Image' && value && (
      <div className="row">
        <img className="Box-Image" src={value} alt={title} />
      </div>
    )

    const inputMarkup = (
      <div className="row">
        <label htmlFor={index}>
          {`Model Option ${index} ${title}`}
        </label>
        <input type="text" id={index} value={value} onChange={this.handleChange} />
      </div>
    )

    return (
      <React.Fragment>
        {inputMarkup}
        {imageMarkup}
      </React.Fragment>
    )
  }
}

export default Model
