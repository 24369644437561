import React from 'react'

const TagInput = React.forwardRef((props, ref) => (
  <input
    ref={ref}
    {...props}
  />
))

export default TagInput
