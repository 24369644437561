import React, { Component } from "react";
import MDSpinner from "react-md-spinner";

export default class LoadingIcon extends Component {
  render() {
    return (
      <span>
        <MDSpinner size={this.props.size} />
      </span>
    );
  }
}
