class Config {

  constructor() {
    this.shopify_admin  = 'https://kithnyc.myshopify.com'; // test
    // this.api_url        = 'http://localhost:5000/raffleapp-madebyseed/us-central1/app'; // test
    // this.api_url        = 'https://raffle-api.madebyseed.com'; // live
    this.api_url        = 'https://us-central1-raffleapp-madebyseed.cloudfunctions.net/app'; // live
    this.seed_api_url   = 'https://shopify-api.madebyseed.com'; // live
    this.socket_url     = 'https://sockets.madebyseed.com'; // live
  }

}

export default ( new Config() );
