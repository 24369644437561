import React from 'react';
import IconWidget from '../_widget_icon';

const SettingsForm = props => {
  return (
    <form onSubmit={props.onFormSubmit}>
      <div className="card">
        <div className="column twelve">
          <h2>TOS</h2>
          <div className="row">
            <label htmlFor="tosHeading">{'TOS heading'}</label>
            <input
              type="text"
              id="tosHeading"
              name="tosHeading"
              value={props.tosHeading}
              onChange={props.onInputChange}
            />
          </div>
          <div className="row">
            <label htmlFor="tosCopy">{'TOS copy'}</label>
            <textarea
              type="textarea"
              id="tosCopy"
              name="tosCopy"
              value={props.tosCopy}
              onChange={props.onInputChange}
            />
          </div>
          <div className="row">
            <label htmlFor="tosLink">{'TOS link'}</label>
            <input
              type="text"
              id="tosLink"
              name="tosLink"
              value={props.tosLink}
              onChange={props.onInputChange}
            />
          </div>
          <div className="row">
            <label htmlFor="tosLabel">{'TOS label'}</label>
            <input
              type="text"
              id="tosLabel"
              name="tosLabel"
              value={props.tosLabel}
              onChange={props.onInputChange}
            />
          </div>

          <div className="row align-center">
            <p>
              <button
                className="button-100"
                type="submit"
                onClick={props.onFormSubmit}
              >
                <IconWidget icon="fa-check" />
                {' Save'}
              </button>
            </p>

            <p>
              <button
                className="button secondary button-100"
                type="button"
                onClick={props.history.goBack}
              >
                <IconWidget icon="fa-times" />
                {' Cancel'}
              </button>
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

const noop = () => {};
SettingsForm.defaultProps = {
  onFormSubmit: noop,
  onInputChnage: noop,
  history: {},
  agreeToTermsHeading: '',
  tosCopy: '',
  tosLink: '',
  tosLabel: '',
};

export default SettingsForm;
