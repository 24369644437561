import React from 'react';
import { randy, startCase } from '../utils';

const noop = () => {};
export default class SettingsWeight extends React.Component {
  static defaultProps = {
    green: 0,
    orange: 0,
    red: 0,
    onWeightChange: noop,
  };

  static weightFactory(state, props) {
    const { green, orange, red } = props;
    const { showResult } = state;

    const renderResult = weight => {
      const res = weight + 'Result';
      return (
        <span className={'SettingsWeight__Result tag ' + weight}>
          {showResult && state[res]}
        </span>
      );
    };

    return {
      green: {
        label: startCase(this.name),
        name: 'green',
        info: 'Has previous order, and is within 80 miles of location',
        value: green,
        result: renderResult('green'),
      },
      orange: {
        label: startCase(this.name),
        name: 'orange',
        info: 'Is within 80 miles of location',
        value: orange,
        result: renderResult('orange'),
      },
      red: {
        label: startCase(this.name),
        name: 'red',
        info: 'Only has email',
        value: red,
        result: renderResult('red'),
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      greenResult: 0,
      orangeResult: 0,
      redResult: 0,
      showResult: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onSimulate = this.onSimulate.bind(this);
    this.renderWeights = this.renderWeights.bind(this);
  }

  onInputChange(e) {
    const { onWeightChange } = this.props;
    const { target } = e;
    const { name, value } = target;
    return onWeightChange(name, value);
  }

  onSimulate() {
    const { green, orange, red } = this.props;
    const weights = { green, orange, red };
    const keys = Object.keys(weights);
    const result = keys.reduce((a, c) => {
      a[c] = 0;
      return a;
    }, {});

    const items = keys.map(key => [key, weights[key]]);
    for (let i = 0; i < 1000; i++) {
      const [key] = randy(items);
      result[key] += 1;
    }

    const state = keys.reduce((a, c) => {
      a[c + 'Result'] = ((100 * result[c]) / 1000).toFixed(2) + ' %';
      return a;
    }, {});

    return this.setState({
      ...state,
      showResult: true,
    });
  }

  renderWeights() {
    const weights = SettingsWeight.weightFactory(this.state, this.props);
    return ['green', 'orange', 'red'].map(w => {
      const weight = weights[w];
      return (
        <div className="row" key={weight.name}>
          <label className="SettingsWeight__Label">
            {weight.name}
            {weight.result}
          </label>
          <input
            type="number"
            name={weight.name}
            value={weight.value}
            onChange={this.onInputChange}
          />
          <em>
            <small>{weight.info}</small>
          </em>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="row">
        <p>Weights</p>
        <hr className="row" />

        {this.renderWeights()}

        <div className="row">
          <div className="pull-right">
            <button className="secondary" onClick={this.onSimulate}>
              Simulate
            </button>
          </div>
        </div>
      </div>
    );
  }
}
