import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import RichTextEditor from './RichTextEditor';
import Models from './Models'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import IconWidget from './_widget_icon';
import fire from './_fire';
import { camelizeSnake, snakifyCamel } from './utils';
import { get, capitalize, snakeCase } from 'lodash';

import './App.css';
import './uptown.css';
import './Account.css';

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const SIZE_LABELS = {
  MENS: "men's",
  WOMENS: "women's",
  KIDS: "kid's",
}

class CampaignEdit extends Component {
  // start
  constructor(props) {
    super(props);
    this.campaignId = this.props.match.params.id;
    this.state = {
      campaignDescription: EditorState.createEmpty(),
      campaignId: '',
      campaignImage: '',
      campaignPrice: '',
      campaignName: '',
      endDate: '',
      hideGender: false,
      hideLocation: false,
      isArchived: false,
      isHidden: false,
      pageUrl: '',
      pageColor: '',
      startDate: '',
      submissionCount: 0,
      submissions: [],
      locations: {},
      sizes: {},
      winnerJsonSettings: '',
      models: Array.from({ length: 4 }, _ => ({})),
      sizeLabel: SIZE_LABELS.MENS,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
    this.normalizeCampaignData = this.normalizeCampaignData.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.handleLocationInputChange = this.handleLocationInputChange.bind(this);
    this.handleSizeInputChange = this.handleSizeInputChange.bind(this);
  }

  componentDidMount() {
    const campaignRef = fire
      .firestore()
      .collection('campaigns')
      .doc(this.campaignId);

    // Listen for raffle submissions
    this.go_submission_count(this.campaignId);

    const stateChange = {
      loadingState: 'hide-loading',
    };

    this.queryDoc(campaignRef, this.normalizeCampaignData)
      .then(state => {
        const { models: newModels = [] } = state
        const stateChanges = prevState => {
          let { models } = prevState;
          newModels.forEach((model, i) => {
            models[i] = model
          })

          return {
            ...prevState,
            ...state,
            ...stateChange,
            ...models,
          }
        }

        return this.setState(stateChanges);
      })
      .then(console.log)
      .catch(console.error);

    this.getSettings();
  }

  handleEditorStateChange(editorState, id) {
    if (id) {
      this.setState({
        [id]: editorState,
      });
    }
  }

  submission_received(data) {
    toast.success('Submission Received');
  }

  handleLocationInputChange(e) {
    const { target } = e;
    const { checked, name } = target;
    const stateChanges = prevState => {
      const payload = {
        ...prevState,
        locations: {
          ...prevState.locations,
          [name]: {
            ...prevState.locations[name],
            checked,
          },
        },
      }

      return payload
    };

    return this.setState(stateChanges);
  }

  handleSizeInputChange(e) {
    const { target } = e;
    const { checked, name } = target;
    const stateChanges = prevState => ({
      ...prevState,
      sizes: {
        ...prevState.sizes,
        [name]: {
          ...prevState.sizes[name],
          checked,
        },
      },
    });

    return this.setState(stateChanges);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const stateChanges = {
      [name]: value,
    };

    this.setState(stateChanges);
  }

  getSettings() {
    const ref = fire
      .firestore()
      .collection('settings')
      .doc('kithnyc.myshopify.com');
    return ref
      .get()
      .then(doc => (doc.exists ? doc.data() : {}))
      .then(camelizeSnake)
      .then(settings => {
        const { locations = {}, shoeSizes = {} } = settings;

        const stateChanges = prevState => {
          const s = Object.keys(shoeSizes).reduce((acc, cur) => {
            const size = shoeSizes[cur];
            const { values } = size;

            return values.reduce((acc1, cur1) => {
              const prevChecked =
                prevState.sizes &&
                prevState.sizes[cur1] &&
                prevState.sizes[cur1].checked;

              acc1[cur1] = {
                title: cur1,
                value: cur1,
                checked: prevChecked || false,
              };

              return acc1;
            }, {});
          }, {});

          const l = Object.keys(locations).reduce((acc, cur) => {
            const location = locations[cur];
            const { title, values } = location;
            const [value] = values;
            const prevChecked =
              prevState.locations &&
              prevState.locations[snakeCase(title)] &&
              prevState.locations[snakeCase(title)].checked;
            acc[snakeCase(title)] = {
              title,
              value,
              checked: prevChecked || false,
            };
            return acc;
          }, {});

          return {
            ...prevState,
            locations: l,
            sizes: s,
            settings: {
              locations: l,
            },
          };
        };

        return this.setState(stateChanges);
      })
      .catch(console.error);
  }

  handleSubmit(event) {
    event.preventDefault();
    toast.info('Saving...');
    const { campaignId } = this;

    const updates = {
      pageUrl: this.state.pageUrl || ' ',
      campaignId,
      campaignName: this.state.campaignName || ' ',
      campaignPrice: this.state.campaignPrice || ' ',
      campaignImage: this.state.campaignImage || ' ',
      campaignDescription:
        this.htmlifyDraft(this.state.campaignDescription) || ' ',
      endDate: this.state.endDate || ' ',
      hideGender: !!this.state.hideGender,
      hideLocation: !!this.state.hideLocation,
      isArchived: !!this.state.isArchived,
      pageColor: this.state.pageColor || ' ',
      startDate: this.state.startDate || ' ',
      winnerJsonSettings: this.state.winnerJsonSettings || ' ',
      isHidden: !!this.state.isHidden,
      locations: this.state.locations,
      sizes: this.state.sizes,
      models: this.state.models,
      sizeLabel: this.state.sizeLabel,
    };

    const normalizedUpdates = snakifyCamel(updates);
    const campaignRef = fire
      .firestore()
      .collection('campaigns')
      .doc(campaignId);

    return campaignRef
      .update(normalizedUpdates)
      .then(() => {
        toast.success('Campaign Saved!');
      })
      .catch(console.error);
  } // end update

  /**
   * Gets doc
   * @param   {Firebase.Firestore.DocumentReference} ref  - Document ref
   * @param   {Function} cb     - Parser function
   * @returns {Promise}
   */
  queryDoc(ref, cb = () => {}) {
    return ref.get().then(doc => {
      if (!doc.exists) {
        return {};
      }
      const data = doc.data();
      const normalizedData = cb(data) || data;
      return { ...normalizedData };
    });
  }

  go_submission_count(campaign_id) {
    var self = this;
    var submissionRef = fire.database().ref('submissions/' + campaign_id);
    submissionRef.on('value', function(snapshot) {
      var submission = snapshot.val();

      if (submission != null) {
        self.setState({
          subscriberCount: submission.submission_count,
        });
      } // end if
    }); // end
  }

  makeEditorState(content) {
    if (content) {
      const { contentBlocks, entityMap } = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    }

    return EditorState.createEmpty();
  }

  htmlifyDraft(editorState) {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }

  // Normalizes campaign data
  normalizeCampaignData(data) {
    const dateFormat = 'YYYY-MM-DDTHH:mm';
    const cameled = camelizeSnake(data);
    const {
      startDate,
      endDate,
      campaignDescription,
      productDescription,
      productImage,
      campaignImage,
    } = cameled;

    return {
      ...cameled,
      startDate: moment(startDate, dateFormat).format(dateFormat),
      endDate: moment(endDate, dateFormat).format(dateFormat),
      campaignDescription: this.makeEditorState(
        campaignDescription || productDescription
      ),
      locations: data.locations,
      campaignImage: campaignImage || productImage,
    };
  }

  // handleModelChange = e => {
  //   const { currentTarget } = e;
  //   const i = currentTarget.getAttribute('data-model-index');
  //   const value = currentTarget.value;
  //   this.setState(state => {
  //     let models = state.models.slice();
  //     models[i] = value;
  //     return { models };
  //   });
  // };

  handleSizeLabelChange = e => {
    e.stopPropagation()
    const { currentTarget } = e
    const { checked, name, id } = currentTarget
    if (checked) {
      this.setState({ [name]: SIZE_LABELS[id] })
    }
  }

  handleModelChange = (value, title, idx) => {
    this.setState(state => {
      let models = state.models.slice()
      models[idx][title.toLowerCase()] = value
      return { models }
    })
  }

  render() {
    const { locations = {}, sizes = {} } = this.state;

    return (
      <div className="content-wrapper-sidebar">
        <div id="outer-container" style={{ height: '100%' }}>
          <section id="page-wrap" className="container">
            <article>
              <div className={'cardx'}>
                <div className="title-div">
                  <Link className="button secondary" to={'/campaigns/'}>
                    <IconWidget icon="fa-long-arrow-left" /> Back
                  </Link>
                  &nbsp;&nbsp;
                  <Link
                    className="button"
                    to={
                      '/campaign-submissions/' +
                      this.props.match.params.id +
                      '/' +
                      this.state.winnersNotified
                    }
                  >
                    <IconWidget icon="fa-user" /> View Submissions ({' '}
                    {this.state.subscriberCount} )
                  </Link>
                  <div className="pull-right">
                    <Link className="button secondary" to={'/settings'}>
                      <IconWidget icon="fa-cog" />
                      {' Settings'}
                    </Link>
                  </div>
                </div>

                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="column eight card">
                      <div className="row">
                        <div className="column twelve">
                          <label htmlFor="campaignName">Campaign Name</label>
                          <input
                            id="campaignName"
                            type="text"
                            name="campaignName"
                            value={this.state.campaignName}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="column twelve">
                          <label htmlFor="campaignPrice">Campaign Price</label>
                          <input
                            id="campaignPrice"
                            type="text"
                            name="campaignPrice"
                            value={this.state.campaignPrice}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="column twelve">
                          <label htmlFor="campaignImage">
                            Campaign Image
                            <br />
                            <em>
                              <small>
                                Upload image to Shopify then copy the CDN URL
                                below, this will be optimal for page speed
                              </small>
                            </em>
                          </label>
                          <input
                            id="campaignImage"
                            type="text"
                            name="campaignImage"
                            autoComplete="off"
                            value={this.state.campaignImage}
                            onChange={this.handleChange}
                          />
                        </div>

                        {this.state.campaignImage && (
                          <div className="column twelve">
                            <img
                              alt=""
                              src={this.state.campaignImage}
                              className="campaign-image"
                            />
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="column twelve">
                          <RichTextEditor
                            id="campaignDescription"
                            label="Campaign Description"
                            wrapperClassName="editor-wrapper"
                            editorClassName="editor-editor"
                            onEditorStateChange={this.handleEditorStateChange}
                            editorState={this.state.campaignDescription}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="column twelve">
                          Winner JSON Settings<br />
                          <textarea
                            name="winnerJsonSettings"
                            className="json-input-box"
                            onChange={this.handleChange}
                            value={this.state.winnerJsonSettings}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <button className="button-100">Save</button>
                      </div>
                      <div className="row">
                        <Link
                          to={
                            '/campaign-submissions/' +
                            this.state.campaignId +
                            '/' +
                            this.state.winnersNotified
                          }
                          className="button secondary button-100"
                        >
                          View All Submissions
                        </Link>
                      </div>
                    </div>

                    {/* SDIEBAR */}
                    <div className="column four">
                      <div className="card">
                        <div className="row">
                          <label htmlFor="startDate">Start Date</label>
                          <input
                            id="startDate"
                            type="datetime-local"
                            name="startDate"
                            value={this.state.startDate}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="row">
                          <label htmlFor="endDate">End Date</label>
                          <input
                            id="endDate"
                            type="datetime-local"
                            name="endDate"
                            value={this.state.endDate}
                            onChange={this.handleChange}
                          />
                        </div>

                        <hr className="row" />

                        <div className="row">Form Settings</div>

                        <div className="row">
                          <label>
                            <input
                              name="hideGender"
                              type="checkbox"
                              checked={this.state.hideGender}
                              onChange={this.handleChange}
                            />
                            Hide Gender
                          </label>
                        </div>
                        <div className="row">
                          <label>
                            <input
                              name="hideLocation"
                              type="checkbox"
                              checked={this.state.hideLocation}
                              onChange={this.handleChange}
                            />
                            Hide Location
                          </label>
                        </div>

                        <hr className="row" />

                        <div className="row">Locations</div>
                        {Object.keys(locations).map((key, i) => {
                          const location = locations[key];
                          return (
                            <div className="row" key={i}>
                              <label>
                                <input
                                  name={snakeCase(location.title)}
                                  type="checkbox"
                                  checked={get(locations, [
                                    snakeCase(location.title),
                                    'checked',
                                  ])}
                                  onChange={this.handleLocationInputChange}
                                />
                                {location.title}
                              </label>
                            </div>
                          );
                        })}

                        <hr className="row" />
                        <div className="row">Size Labels</div>
                        {Object.keys(SIZE_LABELS).map((key, i) => {
                          const label = SIZE_LABELS[key]
                          const title = capitalize(label)
                          return (
                            <div className="row" key={i}>
                              <label>
                                <input
                                  id={key}
                                  type="radio"
                                  name="sizeLabel"
                                  value={title}
                                  checked={this.state.sizeLabel === label}
                                  onChange={this.handleSizeLabelChange}
                                />
                                {title}
                              </label>
                            </div>
                          )
                        })}

                        <hr className="row" />

                        <div className="row">Sizes</div>
                        {Object.keys(sizes)
                          .sort((a, b) => parseFloat(a, 10) - parseFloat(b, 10))
                          .map((key, i) => {
                            const size = sizes[key];
                            return (
                              <div className="row" key={i}>
                                <label key={i}>
                                  <input
                                    name={size.value}
                                    type="checkbox"
                                    checked={get(this.state.sizes, [
                                      size.value,
                                      'checked',
                                    ])}
                                    onChange={this.handleSizeInputChange}
                                  />
                                  {size.title}
                                </label>
                              </div>
                            );
                          })}

                        {this.state.models.map((model, i) =>
                          <Models
                            onChange={this.handleModelChange}
                            title={model.title}
                            image={model.image}
                            idx={i}
                            key={i}
                          />
                        )}


                        <hr className="row" />

                        <div className="row">
                          <label htmlFor="pageUrl">Shopify page URL</label>
                          <input
                            id="pageUrl"
                            type="text"
                            name="pageUrl"
                            value={this.state.pageUrl}
                            onChange={this.handleChange}
                          />
                        </div>

                        <hr className="row" />

                        <div className="row">
                          <label htmlFor="pageColor">Page Color</label>
                          <select
                            id="pageColor"
                            onChange={this.handleChange}
                            name="pageColor"
                            value={this.state.pageColor}
                          >
                            <option value="black">Black</option>
                            <option value="white">White</option>
                          </select>
                        </div>

                        <hr className="row" />

                        <div className="row">
                          <label>
                            <input
                              name="isArchived"
                              type="checkbox"
                              checked={this.state.isArchived}
                              onChange={this.handleChange}
                            />
                            Archive Campaign
                          </label>
                        </div>

                        <div className="row">
                          <label>
                            <input
                              name="isHidden"
                              type="checkbox"
                              checked={this.state.isHidden}
                              onChange={this.handleChange}
                            />
                            Hide Campaign
                          </label>
                        </div>

                        <hr className="row" />

                        <div className="row">
                          <button className="button-100">
                            <IconWidget icon="fa-check" /> Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </article>
          </section>
        </div>
      </div>
    );
  }
  // end
} // end component
export default CampaignEdit;
