import React, { Component } from 'react';
import '../node_modules/font-awesome/css/font-awesome.min.css';

class IconWidget extends Component {
  // start
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <i className={'fa '+this.props.icon} aria-hidden="true"></i>
        );
      }
  // end
}// end component
export default IconWidget;
