import React from 'react';
import TagBox from '../TagBox';
import { noop } from 'lodash';

class OptionFields extends React.Component {
  static defaultProps = {
    onSave: noop,
    tagBox: true,
    titleBox: true,
    onTitleChange: noop,
    onValueChange: noop,
    onInputKeyDown: noop,
    title: '',
    values: [''],
    titlePlaceholder: 'Name',
    valuePlaceholder: 'Value',
    titleError: false,
    boxCount: 1,
  }

  constructor(props) {
    super(props);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleValueAdd = this.handleValueAdd.bind(this);
    this.handleValueRemove = this.handleValueRemove.bind(this);
    this.handleValueInputChange = this.handleValueInputChange.bind(this);
  }

  handleTitleChange(e) {
    const { onTitleChange } = this.props;
    const { value } = e.currentTarget;
    return onTitleChange(value);
  }

  handleValueAdd(value, idx) {
    const { onValueChange, values, singleValue } = this.props;
    let changes = [...values]

    if (singleValue) {
      changes[idx] = value
    } else {
      changes = changes.push(value)
    }

    return onValueChange(changes);
  }

  handleValueRemove(value) {
    const { onValueChange, values } = this.props;
    const changes = values.filter(propValue => propValue !== value);
    return onValueChange(changes);
  }

  handleValueInputChange(idx) {
    const self = this
    return function (e) {
      const { value } = e.currentTarget;
      self.handleValueAdd(value, idx)
    }
  }

  renderInput = (type = 'text', name = 'values') => {
    return (
      this.props.singleValue ? (
        <input
          type="text"
          name="values"
          placeholder={this.props.valuePlaceholder}
          onChange={this.handleValueInputChange(0)}
          onKeyDown={this.props.onInputKeyDown}
          value={this.props.values[0] || ''}
          ref={this.props.inputRef}
        />
      ): (
        <TagBox
          tags={this.props.values}
          onTagAdd={this.handleValueAdd}
          onTagRemove={this.handleValueRemove}
          onSave={this.props.onSave}
          inputRef={this.props.inputRef}
        />
      )
    )
  }

  render() {
    const titleRowClassName = this.props.titleError ? 'row error' : 'row';

    return (
      <React.Fragment>
        <div className={titleRowClassName}>
          <input
            type="text"
            name="title"
            autoComplete="off"
            placeholder={this.props.titlePlaceholder}
            value={this.props.title || ''}
            onChange={this.handleTitleChange}
            onKeyDown={this.props.onInputKeyDown}
            readOnly={this.props.readOnly}
          />
        </div>

        <div className="row">
          {this.renderInput()}
        </div>

        {
          this.props.boxCount === 2 && (
            <div className="row">
              {this.props.values[1] && (
                <img src={this.props.values[1]} alt="value" style={{ display: 'bock', width: '100%' }} />
              )}
              <input
                type="text"
                name="values"
                placeholder="Image"
                onChange={this.handleValueInputChange(1)}
                onKeyDown={this.props.onInputKeyDown}
                value={this.props.values[1] || ''}
                ref={this.props.inputRef}
              />
            </div>
          )
        }
      </React.Fragment>
    );
  }
}

export default OptionFields;
