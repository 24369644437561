import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Auth from './Auth';
import Config from './Config';
import IconWidget from './_widget_icon'
import fire from './_fire'

import './App.css';
import './uptown.css';
import './Account.css';

class CampaignNew extends Component {
  // start
    constructor(props) {
      super(props);
      this.state = {
          campaignName: '',
          startDate: '',
          endDate: '',
          maxEntries: '',
          campaignProducts: '',
          campaignCollection: '',
          winnerCount: '',
          oneEntryPerEmail: true,
          limitByIp: true,
          agreeToTerms: false,
          isHidden: true,
          showLoading: 'hide-loading',
          showButton: 'hide-button',
          showForm: 'show-form',
          showThankYou: 'hide-thank-you',
          token_id: ''
      };

      this.handleChange             = this.handleChange.bind(this);
      this.handleSubmit             = this.handleSubmit.bind(this);
    }

    handleChange(event) {
      const target  = event.target;
      const value   = target.type === 'checkbox' ? target.checked : target.value;
      const name    = target.name;

      if(target.type === 'checkbox'){
        if(target.checked){
          this.setState({
            showButton: 'show-button'
          });
        }else{
          this.setState({
            showButton: 'hide-button'
          });
        }
      }

      this.setState({
        [name]: value
      });

    }

    handleSubmit(event) {
      event.preventDefault();

      this.setState({
        showButton: 'hide-button',
        showLoading: 'show-loading'
      });

      // var data  = new FormData();
      // var token = Auth.getToken();
      // data.append('campaignName', this.state.campaignName);
      // data.append('startDate', this.state.startDate);
      // data.append('endDate', this.state.endDate);
      // data.append('maxEntries', this.state.maxEntries);
      // data.append('campaignProducts', this.state.campaignProducts);
      // data.append('campaignCollection', this.state.campaignCollection);
      // data.append('winnerCount', this.state.winnerCount);
      // data.append('oneEntryPerEmail', this.state.oneEntryPerEmail);
      // data.append('limitByIp', this.state.limitByIp);
      // data.append('agreeToTerms', this.state.agreeToTerms);
      // data.append('token', token);

      var data = {
          campaign_name: this.state.campaignName,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          is_archived: false,
          is_hidden: this.state.isHidden,
      };

      const self = this;
      const ref = fire.firestore().collection('campaigns').doc()
      data.campaign_id = ref.id
      return ref.set(data).then(() => {
        self.setState({
          showForm: 'hide-form',
          showThankYou: 'show-thank-you'
        });
        self.props.history.push('/campaign/'+ref.id);
      })

      // axios.post(Config.api_url+'/api/save-campaign/', data)
      //   .then(function (res) {
      //     console.log(res.data);
      //     self.setState({
      //       showForm: 'hide-form',
      //       showThankYou: 'show-thank-you'
      //     });
      //   })
      //   .catch(function (err) {
      //     console.log(err);
      //     self.setState({
      //       showButton: 'show-button',
      //       showLoading: 'hide-loading'
      //     });
      //   });

    }

    render() {
      return (
        <div className="content-wrapper-sidebar">
          <section id="page-wrap" className="container">
            <article>
              <div className="card">

                <div className="title-div">
                  <Link className="button secondary" to={"/campaigns/"}><IconWidget icon="fa-long-arrow-left" /> Back</Link>
                </div>

                <div className={this.state.showThankYou}>
                  <h1>Thank You.</h1>
                </div>

                <div className={this.state.showForm}>

                      <form onSubmit={this.handleSubmit}>
                        <div className="row">
                          <div className="column twelve">
                            Campaign Name<br />
                            <input type="text" name="campaignName" value={this.state.campaignName} onChange={this.handleChange} />
                          </div>
                        </div>
                        <div className="row">
                          <label>
                            <input
                              name="isHidden"
                              type="checkbox"
                              checked={this.state.isHidden}
                              onChange={this.handleChange}
                            />
                            Hide Campaign
                          </label>
                        </div>
                        {/*
                        <div className="row">
                          <div className="column six">
                            Start Date<br />
                          <input type="date" name="startDate" value={this.state.startDate} onChange={this.handleChange} />
                          </div>
                          <div className="column six">
                            End Date<br />
                          <input type="date" name="endDate" value={this.state.endDate} onChange={this.handleChange} />
                          </div>
                        </div>


                        <div className="row">
                          <div className="column six">
                            Max Entries<br />
                          <input type="number" min="0" name="maxEntries" value={this.state.maxEntries} onChange={this.handleChange} />
                          </div>
                          <div className="column six">
                            How many customers can win?<br />
                          <input type="number" min="0" name="winnerCount" value={this.state.winnerCount} onChange={this.handleChange} />
                          </div>
                        </div>

                        <div className="row">

                          <input
                            name="agreeToTerms"
                            type="checkbox"
                            checked={this.state.agreeToTerms}
                            onChange={this.handleChange} />

                          Customers are required to agree to terms
                        </div>
                        <div className="row">

                          <input
                            name="limitByIp"
                            type="checkbox"
                            checked={this.state.limitByIp}
                            onChange={this.handleChange} />

                          Limit one entry per IP address
                        </div>
                        <div className="row">

                          <input
                            name="oneEntryPerEmail"
                            type="checkbox"
                            checked={this.state.oneEntryPerEmail}
                            onChange={this.handleChange} />

                          Limit one entry per email address
                        </div>
                        */}
                        <div className="align-center">
                          <input type="submit" className="button-100" value="Submit" /><br />
                          <Link to="/campaigns"><IconWidget icon="fa-times" />  Cancel</Link>
                        </div>
                        <div className={this.state.showLoading}>
                          <img alt='' src="/loading-content.gif" />
                        </div>
                      </form>
                  </div>
                </div>
              </article>
            </section>
          </div>
        );
      }
  // end
}// end component
export default CampaignNew;
