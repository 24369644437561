import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

/**
 * Start cases snake and camel cased text
 * @param {String} snake
 */
export function startCase(snake) {
  const str = snake.replace(/_(.)/g, ' ').replace(/([A-Z])/g, ' $1');
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Camel cases snake cased strings
 * @param {String} str
 */
export const camelCase = str =>
  str.replace(/_(.)/g, (_, p1) => p1.toUpperCase());

/**
 * Transforms snake cased keys into camel cased keys
 * @param   {Object|Array<Object>} snaked
 * @returns {Object|Array<Object>}
 */
export function camelizeSnake(snaked) {
  if (Array.isArray(snaked)) {
    return snaked.map(camelizeSnake);
  }

  const keys = Object.keys(snaked);

  return keys.reduce((a, key) => {
    const value = snaked[key];

    const cameled = camelCase(key);

    a[cameled] =
      value && typeof value === 'object' && !Array.isArray(value)
        ? camelizeSnake(value)
        : value;

    return a;
  }, {});
}

/**
 * Transforms camel cased keys into snake cased keys
 * @param   {Object|Array<Object>} snaked
 * @returns {Object|Array<Object>}
 */
export function snakifyCamel(cameled) {
  if (Array.isArray(cameled)) {
    return cameled.map(snakifyCamel);
  }

  const keys = Object.keys(cameled);
  return keys.reduce((a, key) => {
    const value = cameled[key];
    const snaked = key
      .replace(/([A-Z])/g, '_$1')
      .replace(/^_/, '')
      .toLowerCase();

    a[snaked] =
      value && (typeof value === 'object') && !Array.isArray(value)
        ? snakifyCamel(value)
        : value;

    return a;
  }, {});
}

/**
 * Creates state for draft js
 * @param {ContentState} content
 */
export function makeEditorState(content) {
  if (content) {
    const { contentBlocks, entityMap } = htmlToDraft(content);
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  return EditorState.createEmpty();
}

/**
 * Turns editorstate into readable html
 * @param {EditorState} editorState
 */
export function htmlifyDraft(editorState) {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
}

/**
 * Creates an object with selected properties
 * @param {Array<String>} path
 * @returns {(obj: Object) => Object}
 */
export const pick = path => obj =>
  path.reduce((a, p) => {
    a[p] = obj[p];
    return a;
  }, {});

/**
 * Picks randomnly from an array of weighted items
 * @param {Array<Array>} x - Weighted items
 * @returns {Array<Array>} - [selectedItem, index]
 */
export const randy = x => {
  if (!Array.isArray(x)) {
    return x;
  }

  const items = x.map(y => y[0] || y);
  const weights = x.map(y => parseInt(y[1] || 10, 10));

  const total = weights.reduce((a, c) => a + c, 0);
  let rand = parseInt(Math.random() * total, 10);

  for (let i = 0; i < x.length; i++) {
    if (rand < weights[i]) {
      return [items[i], i];
    }

    rand -= weights[i];
  }
};

/**
 * Selects weighted random from a  bag
 * @param {Array<Array>} items
 * @param {?Number} length
 * @returns {Object}
 */
export const randyBag = (items, length = 1) => {
  const initialValue = { result: [], remaining: [] };

  if (!items.length) {
    return initialValue;
  }

  return Array.from({ length }).reduce((a, c, i) => {
    const remainingItems = a.remaining.length ? a.remaining : items;
    const [selected, index] = randy(remainingItems);
    a.result.push(selected);
    a.remaining = remainingItems.filter((_, j) => j !== index);

    return a;
  }, initialValue);
};

/**
 * Shuffles an array
 * @param   {Array<Array>} items
 * @param   {?Number} fraction  - First x percentage of returned list. Rounds up
 * @param   {?Number} take      - Number of items to take before processing fractional
 * @returns {Object}
 */
export const randyShuffle = (items, fraction = 1, take = 1) => {
  items = items.filter(item => item[1] !== '0')
  const { result } = randyBag(items, items.length);
  const randyResult = result.slice(0, take);
  const end = Math.ceil(randyResult.length * fraction);
  const randyRemaining = result.slice(take, end + take);

  return {
    result: randyResult,
    remaining: randyRemaining,
  };
};

/**
 * Access object by string dot notation
 * @param {String} path
 * @param {Object} obj
 */
export const fieldPath = (path, obj) => {
  return path.split('.').reduce((a, c) => (a ? a[c] : undefined), obj);
};

/**
 * Logs argument into console before returning the argument
 * @param {*} x
 */
export const log = x => {
  console.log(x);
  return x;
};

// Reverses order of arguments
export const flip = fn => (...params) => fn(...params.reverse());

export const handle = str => {
  return String(str)
    .toLowerCase()
    .replace(/^-+|-+$|'/g, '')
    .replace(/[^\w\u00C0-\u024f]+/g, '-')
}

/**
 * Generates UID
 * @param {String} prefix
 * @returns {Function|String}
 */
export const idFactory = prefix => {
  prefix = prefix || Math.ceil(Math.random() * 1000000).toString(16)
  let idx = 0
  return function () {
    return `${prefix}_${idx++}`
  }
}
