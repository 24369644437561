import React, { Component } from 'react';
import MDSpinner from "react-md-spinner";
import fire from './_fire';


class SubmissionCount extends Component {
  // start
    constructor(props) {
      super(props);
      this.state = {
          campaignId: '',
          subscriberCount: ''
      };
    }

    go_submission_count(campaign_id){
      var self = this;
      var submissionRef  = fire.database().ref('submissions/' + campaign_id);
      submissionRef.on('value', function(snapshot) {
        var submission = ( snapshot.val() );

        if(submission != null){
          self.setState({
            subscriberCount: submission.submission_count
          });
        } // end if

      }); // end
    }

    componentDidMount(){

      var campaign_id  = this.props.id;
      this.go_submission_count(campaign_id)

    } // end function

    render() {

      return (<span>
        {this.state.subscriberCount}
        </span>
        );
      }
  // end
}// end component
export default SubmissionCount;
