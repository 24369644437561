import './ProductControl.css';
import React from 'react';
import IconWidget from '../_widget_icon';
import ProductForm from './ProductForm';
import { toast } from 'react-toastify';
import { startCase } from '../utils';

const noop = () => {};
class ProductControl extends React.Component {
  static defaultProps = {
    campaignId: '',
    history: {},
    productId: '',
    title: '',
    saveProduct: noop,
    product: {
      productImage: '',
      productOptions: {},
      productPrice: '',
      productSubTitle: '',
      productTitle: '',
    },
    settings: {
      apparelBottomSizes: {},
      apparelTopSizes: {},
      locations: {},
      shoeSizes: {},
    },
  };

  static mapProps(props) {
    const { product } = props;
    return product
  }

  static trimProductOptions(options) {
    if (!options || typeof options !== 'object' || Array.isArray(options)) {
      return options;
    }

    return Object.keys(options).reduce((a, key) => {
      const opt = options[key];

      if (opt.values) {
        if (opt.values.length) {
          a = a || {};
          a[key] = opt;
        }
      }

      if (!opt.values) {
        const filled = this.trimProductOptions(opt);
        if (filled) {
          a = a || {};
          a[key] = filled;
        }
      }

      return a;
    }, null);
  }

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProductRemove = this.handleProductRemove.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.renderOptionGroups = this.renderOptionGroups.bind(this);
    this.renderOptionValue = this.renderOptionValue.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);

    this.state = ProductControl.mapProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.productId && nextProps.productId) {
      this.setState(ProductControl.mapProps(this.props));
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const productOptions = ProductControl.trimProductOptions(
      this.state.productOptions
    );
    const payload = { ...this.state, productOptions };

    toast.info('Saving...');
    return this.props
      .saveProduct(payload)
      .then(() => toast.success('Product Saved!'))
      .catch(error => {
        console.error(error);
        return toast.error('Something went wrong...');
      });
  }

  handleProductRemove() {
    toast.info('Removing...');
    this.props
      .removeProduct()
      .then(() => toast.success('Product Removed!'))
      .then(this.props.history.goBack)
      .catch(error => {
        console.error(error);
        return toast.error('Something went wrong...');
      });
  }

  handleInputChange(e) {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  }

  handleOptionChange(e) {
    const { name, checked } = e.target;
    const [option, optionGroup, optionValue] = JSON.parse(name);
    const stateChanges = (prevState, prevProps) => {
      const prevOptions = prevState.productOptions;
      const prevOption = prevOptions[option] || {};
      const prevGroup = prevOption[optionGroup] || {};
      const prevValues = prevGroup.values || [];
      const values = checked
        ? [...prevValues, optionValue]
        : prevValues.filter(v => v !== optionValue);

      const title = prevProps.settings[option][optionGroup].title;
      const ret = {
        productOptions: {
          ...prevOptions,
          [option]: {
            ...prevOption,
            [optionGroup]: { title, values },
          },
        },
      };

      return ret;
    };

    return this.setState(stateChanges);
  }

  renderOptionValue(value, path) {
    const key = JSON.stringify([...path, value]);
    return (
      <li key={key}>
        <label htmlFor={key}>
          <input
            type="checkbox"
            name={key}
            id={key}
            onChange={this.handleOptionChange}
          />
          {value}
        </label>
      </li>
    );
  }

  renderOptionGroups(groups, path) {
    if (!groups) {
      return null
    }
    const keys = Object.keys(groups);
    return keys.map((key, i) => {
      const group = groups[key];
      const nextPath = [...path, key];
      return (
        <div key={key} className="row">
          <p>{group.title}</p>
          <ul className="Checkbox-Grid">
            {group.values.map(v => this.renderOptionValue(v, nextPath))}
          </ul>
          {keys[i + 1] && <hr />}
        </div>
      );
    });
  }

  renderOptions() {
    const { settings } = this.props;
    return Object.keys(settings).map(key => {
      const groups = settings[key];
      const casedKey = startCase(key);
      return (
        <div className="card" key={key}>
          <div className="row">{casedKey}</div>
          <hr className="row" />
          {this.renderOptionGroups(groups, [key])}
        </div>
      );
    });
  }

  render() {
    const { title, history, removeProduct } = this.props;

    return (
      <div id="outer-container" style={{ height: '100%' }}>
        <section className="container">
          <div className="full-width">
            <article>
              <div className="title-div">
                <button className="secondary" onClick={history.goBack}>
                  <IconWidget icon="fa-long-arrow-left" /> Back
                </button>
              </div>

              <h2 style={{ marginBottom: '0', marginLeft: '1rem' }}>{title}</h2>

              {removeProduct && (
                <div className="pull-right" style={{ marginLeft: 'auto' }}>
                  <button
                    className="link"
                    onClick={this.handleProductRemove}
                    style={{ color: '#bf0711' }}
                  >
                    {'Remove'}
                  </button>
                </div>
              )}
            </article>

            <article>
              <form className="full-width" onSubmit={this.handleSubmit}>
                <div className="column eight card">
                  <ProductForm
                    onInputChange={this.handleInputChange}
                    onEditorStateChange={this.handleEditorStateChange}
                    productImage={this.state.productImage}
                    productTitle={this.state.productTitle}
                    productSubTitle={this.state.productSubTitle}
                    productDescription={this.state.productDescription}
                    productPrice={this.state.productPrice}
                  />
                  <div className="align-center">
                    <button
                      className="button-100"
                      type="submit"
                      onClick={this.handleSave}
                    >
                      <IconWidget icon="fa-check" />
                      {' Save'}
                    </button>

                    <button
                      className="link"
                      type="button"
                      onClick={history.goBack}
                    >
                      <IconWidget icon="fa-times" />
                      {' Cancel'}
                    </button>
                  </div>
                </div>

                <div className="column four">
                  {this.renderOptions(this.state, this.props)}
                </div>
              </form>
            </article>
          </div>
        </section>
      </div>
    );
  }
}

export default ProductControl;
